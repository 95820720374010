@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../atoms/semantic/headlines/headlines_mixins';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/grid/grid_variables';
@import '../../subatoms/typography/typography_variables';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../page-sector/page-sector-mixins';

$header-offset-mobile: spacing('6');
$header-offset-desktop: spacing('11');

$logos-primary-dim-lg: rem-calc(100);
$logos-primary-dim: rem-calc(70);
$logos-secondary-dim-lg: rem-calc(75);
$logos-secondary-dim: rem-calc(50);

.page-sector {
  &--intro {
    &:not(.page-sector--intro-no-image) {
      padding-top: $header-offset-mobile;

      @include mq('lg') {
        padding-top: $header-offset-desktop;
      }

      @include mq($max-width: 'md') {
        &.page-sector--bg-mobile-grey {
          background-color: hsl(var(--grey-wild-sand));
        }
      }
    }

    .page-sector__header {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
    }

    .page-sector__head {
      display: flex;
      padding-top: $spacing-desktop-l;

      @include mq($max-width: 'md') {
        position: relative;
        margin-top: $spacing-desktop-m * -1;
        padding: $spacing-mobile-l spacing('2') 0;
        border-radius: rem-calc(10);
      }

      &-media {
        position: relative;
        overflow: visible;
        margin-top: $header-offset-mobile * -1;

        @include mq('lg') {
          margin-top: $header-offset-desktop * -1;
        }

        img {
          max-width: none;
          object-fit: cover;
        }
      }

      .l-row--center {
        flex: 1;
      }
    }

    &-image {
      &.image {
        &--mobile {
          &-small {
            @include mq($max-width: 'md') {
              height: rem-calc(200);
            }
          }
          &-medium {
            @include mq($max-width: 'md') {
              height: rem-calc(275);
            }
          }
          &-large {
            @include mq($max-width: 'md') {
              height: rem-calc(400);
            }
          }
        }

        &--desktop {
          &-small {
            @include mq('lg') {
              height: rem-calc(200);
            }
          }
          &-medium {
            @include mq('lg') {
              height: rem-calc(275);
            }
          }
          &-large {
            @include mq('lg') {
              height: rem-calc(487);
            }
          }
        }
      }
    }

    &-has-logo {
      .page-sector__head {
        @include mq($max-width: 'md') {
          padding-top: spacing('6');
        }
      }
    }

    .page-sector__logos {
      &-primary,
      &-secondary {
        list-style: none;
        position: absolute;
        display: flex;
        align-content: center;
      }
      &-primary {
        margin: 0;
        padding: 0;
        transform: translate(-50%, 50%);
        z-index: 1;
        left: 50%;
        bottom: calc(#{$spacing-desktop-m});

        @include mq('lg') {
          bottom: 0;
        }

        li {
          margin: 0 spacing('0.5');
          @include mq('lg') {
            margin: 0 spacing('1');
          }
        }

        img {
          width: $logos-primary-dim;
          height: $logos-primary-dim;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0 0 rem-calc(10) 0 hsl(var(--black) / 0.3);
          @include mq('lg') {
            width: $logos-primary-dim-lg;
            height: $logos-primary-dim-lg;
          }
        }
      }
      &-secondary {
        top: rem-calc(24);
        left: 0;
        @include mq('xl') {
          top: auto;
          bottom: 0;
        }
        li {
          margin: 0 spacing('1') 0 0;
          @include mq('lg') {
            margin-right: spacing('2');
          }
        }
        img {
          width: auto;
          height: $logos-secondary-dim;
          @include mq('lg') {
            height: $logos-secondary-dim-lg;
          }
        }
      }
    }

    .content-btns {
      margin-top: spacing('4');

      @include mq('lg') {
        margin-top: spacing('6');
      }

      & + .content-btns {
        margin-top: 0;
      }

      &__block {
        display: flex;
        justify-content: center;
        margin-top: 0;
      }

      .btn {
        &--primary {
          margin-bottom: 0;
        }

        &--text {
          margin-top: spacing('2');
        }
      }
    }

    // Page intro without desktop & mobile images
    &-no-image {
      margin-top: spacing('2.5');
      @include mq('lg') {
        margin-top: spacing('2');
      }
      .page-sector {
        &__head {
          margin-top: 0;
          padding: 0;
        }

        &__hdln {
          margin-bottom: 0;

          & + .page-sector__sbln {
            margin-top: spacing('3');
          }
        }
      }
    }
  }
}
