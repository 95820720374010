.icon {
  color: inherit;
  display: inline-block;
  // fill: currentColor;
  font: inherit;
  height: 1em;
  // stroke: currentColor;
  // stroke-width: 0;
  width: 1em;

  &--larger {
    font-size: 1.5rem;
    vertical-align: bottom;
  }
}
