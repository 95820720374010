@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import '../../subatoms/color/color_functions';

$page-sector-themes: color-themes();

.t {
  @each $theme-name, $theme-value in $page-sector-themes {
    $contrast-color: color-contrast($theme-value);

    &-bg--#{$theme-name} {
      background: $theme-value;
      color: $contrast-color;
    }

    &-bg-hover--#{$theme-name} {
      @include mq('lg') {
        &:hover {
          background: $theme-value;
          color: $contrast-color;
        }
      }

      &:focus,
      &.focused {
        background: $theme-value;
        color: $contrast-color;
      }

      &:active {
        background-color: darken($theme-value, 20%);
        background-size: 100%;
        box-shadow: none;
      }
    }
  }

  @each $theme-name, $theme-value in $page-sector-themes {
    &-color--#{$theme-name} {
      color: $theme-value;
    }

    &-color-hover--#{$theme-name} {
      @include mq('lg') {
        &:hover {
          color: $theme-value;
        }
      }

      &:focus,
      &.focused {
        color: $theme-value;
      }
    }
  }

  @each $theme-name, $theme-value in $page-sector-themes {
    &-border--#{$theme-name} {
      border-color: $theme-value;
    }
  }
}
