@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import './skeleton-utilities';

/* skeleton used for market tile */
.skeleton {
  &.market-tile__headline {
    margin-bottom: 0 !important;
  }

  &.market-tile__link--underline {
    flex-basis: 50%;
    text-align: right;
    padding-left: 0;
    margin: 0 0 0 spacing('5') !important;
  }

  &--market-service {
    .skeleton {
      &--image {
        width: rem-calc(40);
        height: rem-calc(40);

        @include mq('lg') {
          width: rem-calc(56);
          height: rem-calc(56);
        }
      }
      &--market-service-detail {
        margin-left: rem-calc(16);
      }
    }
  }
}
