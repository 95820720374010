@import 'scss-mixins-functions-variables/scss/reset/list/reset-list-mixins';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import '../../../subatoms/color/color_functions';
@import '../../../subatoms/grid/grid_mixins';
@import '../../../subatoms/grid/grid_variables';
@import '../../../atoms/unified-spacing/unified-spacing-mixin';

@mixin tiles-per-row {
  @each $breakpoint-name, $breakpoint-config in $breakpoints {
    @if map-has-key($breakpoint-config, 'tiles') {
      @media (min-width: map-get($breakpoint-config, 'min-width')) {
        --tiles-per-row: #{map-get($breakpoint-config, 'tiles')};
      }
    }
  }
}

// Styles for tiles list
.tile-list {
  --column-width: calc(100% / var(--tiles-per-row) - var(--gutter) + (var(--gutter) / var(--tiles-per-row)));
  @include reset-list();
  @include tiles-per-row;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, var(--column-width));
  gap: var(--gutter);
  justify-content: center;

  &--center {
    justify-content: center;
  }

  &--left {
    justify-content: left;
  }

  > li {
    margin: 0;
    min-width: 0; /* fix grid content overflow issue */
  }

  &__item--highlight {
    grid-column-end: span 2;
  }

  &__item--placeholder {
    min-height: rem-calc(350);
  }

  &--edit-mode {
    display: block;
    width: auto;

    > li {
      margin-bottom: var(--gutter);
      margin-inline: auto;
      max-width: var(--column-width);

      &.tile-list__item--highlight {
        max-width: calc(var(--column-width) * 2);
      }
    }
  }

  & + .content-btns {
    @include vertical-margin(top, 'm');
  }
}

.tsr {
  background-color: hsl(var(--white));
  border-radius: rem-calc(10);
  color: hsl(var(--grey-stone));
  overflow: hidden;
  position: relative;
}

.tile--in-grid {
  height: 100%;
}

// Styles for teaser items
.tsr {
  background: hsl(var(--white));
  border-radius: rem-calc(15);
  color: hsl(var(--grey-stone));
  display: flex;
  flex-direction: column;
  font-size: rem-calc(10);
  z-index: 0;

  @include mq('lg') {
    font-size: rem-calc(13);
  }
}
