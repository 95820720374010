@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/aspect-ratio/aspect-ratio-mixins';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/typography/typography_variables';
@import '../../subatoms/elevation/elevation_variables';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/z-indexes/z-indexes_functions';

//general
$group-width: rem-calc(130);
$button-height: rem-calc(40);
$top-padding-mobile: spacing('8');
$top-padding-desktop: spacing('10');

// breakpoints
$breakpoint-tablet: 'md';
$breakpoint-desktop: 'lg';

.jump-marks {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: $typography-family-akko;
  position: sticky;
  top: 0;
  z-index: z('jump-marks');

  &:first-child {
    margin-top: rem-calc(20);
  }

  @include mq($breakpoint-desktop) {
    top: calc(var(--sticky-header-height) - #{rem-calc(1)});

    &:first-child {
      margin-top: 0;
    }
  }

  &--storybook {
    top: 0;
  }

  &__header {
    align-items: center;
    color: hsl(var(--red));
    display: flex;
    font-size: rem-calc(12);
    font-weight: $typography-weight-medium;
    justify-content: center;
    line-height: calc(14 / 12);
    position: relative;

    @include mq($breakpoint-desktop) {
      font-size: rem-calc(13);
      line-height: calc(14 / 13);
      min-height: rem-calc(76);
    }
  }

  &__header-wrapper {
    background-color: hsl(var(--white));
    box-shadow: map-get($elevations, '05_dp');
    width: 100%;
    z-index: z('jump-marks');
  }

  &__target {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: $button-height;
    justify-content: center;
    margin: rem-calc(16) 0;
    min-width: $group-width;
    padding: rem-calc(5) rem-calc(15);
    text-align: center;
    width: $group-width;
    z-index: 2;

    &.t-bg--red,
    &.t-bg--green-foerderpenny {
      background-color: transparent;
    }

    &.t-bg--red {
      color: hsl(var(--red));
    }

    &.t-bg--green-foerderpenny {
      color: hsl(var(--green-foerderpenny));
    }

    abbr {
      pointer-events: none;
    }
  }

  &__target-selected {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 0.3s ease-in-out;
    width: $group-width;
    z-index: 1;

    .shape {
      display: block;
      height: 100%;
      padding: rem-calc(16) rem-calc(11);
      position: relative;
      width: 100%;

      &::before {
        border-radius: rem-calc(20);
        box-shadow: map-get($elevations, '03_dp_red');
        content: '';
        display: block;
        height: $button-height;
        margin-bottom: rem-calc(20);
        width: 100%;
      }

      &::after {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid hsl(var(--white));
        content: '';
        display: none;
        height: 0;
        margin: 0 auto;
        width: 0;
      }
    }

    &.t-bg--green-foerderpenny,
    &.t-bg--red {
      background-color: transparent;
    }

    &.t-bg--green-foerderpenny {
      .shape {
        &::before {
          box-shadow: 0 3px 14px 2px rgba(6, 157, 98, 0.12), 0 5px 5px -3px rgba(6, 157, 98, 0.2);
        }
      }
    }
  }

  .swiper {
    display: flex;
    padding-bottom: rem-calc(10);
    overflow: visible;
    position: relative;
    z-index: 1;

    &__container {
      margin-bottom: rem-calc(-10);
      overflow-x: auto;
      scroll-snap-points-x: repeat(100%);
      scroll-snap-type: x mandatory;
      z-index: 1;

      .icon {
        background: transparent;

        &.t-bg--green-foerderpenny {
          color: hsl(var(--green-foerderpenny));
        }

        &.t-bg--red {
          color: hsl(var(--red));
        }
      }
    }

    &__control {
      background: hsl(var(--white));
      bottom: 0;
      cursor: pointer;
      padding: rem-calc(10) rem-calc(5);
      position: absolute;
      top: 0;
      z-index: 2;

      @include mq($breakpoint-desktop) {
        display: none;
      }

      &--left {
        left: 0;
        &::after {
          background: linear-gradient(90deg, hsl(var(--white)) 0%, hsl(var(--white) / 0) 100%);
          bottom: 0;
          content: '';
          display: block;
          pointer-events: none;
          position: absolute;
          right: rem-calc(-46);
          top: 0;

          width: rem-calc(46);
        }
      }

      &--right {
        right: 0;
        &::before {
          background: linear-gradient(-90deg, hsl(var(--white)) 0%, hsl(var(--white) / 0) 100%);
          bottom: 0;
          content: '';
          display: block;
          left: rem-calc(-46);
          pointer-events: none;
          position: absolute;
          top: 0;

          width: rem-calc(46);
        }
      }

      &--hide {
        display: none;
      }
    }

    &__inactive {
      .swiper__control {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
  }
}

.jump-mark {
  height: 0;
  margin-bottom: 0;
  position: relative;
  top: 0;
  visibility: hidden;

  &--edit-mode {
    height: auto;
    visibility: visible;
  }
}
