// https://docs.usercentrics.com/#/smart-data-protector?id=styling-customization-css
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/elevation/elevation_variables';
@import '../../subatoms/typography/typography_variables';
@import '../../subatoms/grid/grid_variables';
@import '../../atoms/semantic/headlines/headlines_mixins';

// usercentrics general styling
.uc-embedding-container {
  .uc-embedding-wrapper {
    padding: rem-calc(16);
    border-radius: rem-calc(10);
    background-color: hsl(var(--white) / 0.8);
    backdrop-filter: blur(rem-calc(10));

    h3 {
      @include h4();
      color: hsl(var(--grey-stone));
      margin: 0;
    }

    .uc-embedding-buttons {
      padding-bottom: rem-calc(20);
    }

    .uc-embedding-more-info,
    .uc-embedding-accept {
      display: flex;
      padding: rem-calc(12) rem-calc(40);
      border-radius: rem-calc(24);
      background: hsl(var(--red));
      color: hsl(var(--white));
      box-shadow: map-get($elevations, '08_dp_b_3');
      height: auto;
      width: auto;
      margin: 0;
      text-align: center;
      font: $typography-weight-medium #{rem-calc(15)} $typography-family-akko;
      line-height: unset;

      @include mq('lg') {
        &:hover {
          background: hsl(var(--red)) radial-gradient(circle, transparent 1%, hsl(var(--red)) 1%) center / 15000%;
          box-shadow: 0 rem-calc(5) rem-calc(5) rem-calc(-3) hsl(var(--white) / 0.3), 0 rem-calc(2) rem-calc(1) rem-calc(-1) hsl(var(--white) / 0.24), 0 rem-calc(1) rem-calc(1) 0 hsl(var(--white) / 0.3);
        }
      }
      &:focus,
      &.focused {
        box-shadow: inset 0 0 0 rem-calc(2) hsl(var(--red)), inset 0 0 0 rem-calc(4) hsl(var(--white));
        outline: none;
      }
      &:active {
        background-color: hsl(var(--hue) calc(var(--saturation) + 82%) calc(var(--lightness) + 24%));
        background-size: 100%;
        box-shadow: none;
      }
    }

    .description-text,
    .not-existing-service {
      margin-block: rem-calc(16);
      font: $typography-weight-semi-bold #{rem-calc(15)} / #{rem-calc(21)} $typography-family-sans;
      color: hsl(var(--grey-stone));

      @include mq('lg') {
        font-size: rem-calc(20);
        line-height: 1.4;
      }
    }

    // remove "more info" button
    .uc-embedding-more-info,
    // remove "powered by" text
    span:has(.uc-embedding-powered-by) {
      display: none;
    }
  }

}

.market-finder__map,
.video__container {
  .uc-embedding {
    &-container {
      background: hsl(var(--black) / 0.5);
    }
    &-wrapper {
      max-width: calc(100% - #{rem-calc(35)});
    }
  }
}

.detail-block__carousel-slide {
  .uc-embedding {
    &-wrapper {
      backdrop-filter: none;
      box-shadow: none;
    }
  }
}

// usercentrics styling on product detail for the youtube video
.detail-block__carousel-slide {
  .uc-embedding {
    &-container {
      width: 100%;
    }
  }
}

.video__container {
  .uc-embedding {
    &-container {
      z-index: 2;
      width: 100%;
      height: 100%;
      min-height: 100%;
    }
  }
}

// usercentrics styling on market info for google maps
.market-finder__map {
  .uc-embedding {
    &-container {
      min-height: 100%;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background: hsl(var(--black) / 0.5);
      }
    }
  }
}

// usercentrics styling on market zip search modal
.market-modal__wrapper {
  .uc-embedding-container {
    margin-inline: auto;
    width: 100%;
    height: 100%;
    min-height: unset;
    max-height: unset;

    .uc-embedding-wrapper {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      min-height: unset;
      border: none;
      border-radius: 0;
      backdrop-filter: unset;
      background: transparent;
      box-shadow: none;
      position: static;
      transform: translate(0, 0);
      overflow: unset;

      h3 {
        display: none;
      }
    }
  }

}

// usercentrics styling on market finder
.market-finder__consent {
  display: none;
  border: 0;
  padding: rem-calc(24);
  border-radius: rem-calc(16) rem-calc(16) 0 0;
  background: hsl(var(--white));
  inset-block-start: auto;

  @include mq($max-width: 'md') {
    max-width: unset;
    transform: translateY(100%);
  }

  @include mq('lg') {
    inset-block-start: 0;
    border-radius: rem-calc(16);
  }

  &::backdrop {
    background-color: hsl(var(--black) / 0.5);
  }

  &[open] {
    display: flex;
    animation: animateInModal 0.25s ease-in-out forwards;

    @include mq($max-width: 'md') {
      animation: animateInSlideModal 0.25s ease-in-out forwards;
    }
  }

  &:not([open]) {
    pointer-events: none;
  }

  &:has(#market-search-consent:empty) {
    display: none;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &-btn {
    margin-bottom: rem-calc(-25);
    padding: rem-calc(8);
    z-index: 1;
  }

  .uc-embedding-container {
    width: 100%;
    height: auto;
    min-height: unset;

    @include mq('lg') {
      width: rem-calc(400);
    }
  }

  .uc-embedding-wrapper {
    width: 100%;
    height: 100%;
    max-width: unset;
    min-width: unset;
    max-height: unset;
    min-height: unset;
    box-shadow: none;
    position: static;
    transform: none;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    border-radius: 0;
    overflow: unset;
    backdrop-filter: unset;
  }
}

@keyframes animateInModal {
  0% {
    display: none;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animateInSlideModal {
  0% {
    display: none;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

html:has(.market-finder__consent[open]) {
  overflow: hidden;
}
