/* -------------------- *\
	SubAtomics
\* -------------------- */

@import 'scss-mixins-functions-variables/scss/typography/font-stacks-variables';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/layout/visually-hidden-mixins';
@import '../components/subatoms/typography/typography_variables';
@import '../components/subatoms/color/color_root';
@import '../components/subatoms/color/color_functions';

$root-background-color: hsl(var(--grey-wild-sand));
$root-typography-color: hsl(var(--grey-midnight));
$root-typography-family: $typography-family-sans;
$root-typography-line-height: calc(21 / 15);
$root-typography-weight: $typography-weight-semi-bold;
$full-width-responsive-images: true;

@import '../components/subatoms/typography/font-face-fallback';
@import '../components/subatoms/typography/font-face';
@import '../components/subatoms/reboot/reboot';
@import '../components/subatoms/button/button';
@import '../components/subatoms/grid/grid';
@import '../components/subatoms/viewport/viewport';
@import '../components/subatoms/visibility/visibility';
@import '../components/subatoms/ellipsis/ellipsis';
@import '../components/subatoms/hyphens/hyphens';
@import '../components/subatoms/shorthand/position';
@import '../components/subatoms/shorthand/overflow';
@import '../components/subatoms/shorthand/display';

/* -------------------- *\
	Atoms
\* -------------------- */
@import '../components/atoms/semantic/a/a';
@import '../components/atoms/semantic/p/p';
@import '../components/atoms/semantic/ul/ul';
@import '../components/atoms/semantic/headlines/headlines';
@import '../components/atoms/attribute/attribute';
@import '../components/atoms/buttons/button';
@import '../components/atoms/buttons/link-button';
@import '../components/atoms/icons/icon';
@import '../components/atoms/pill/pill';
@import '../components/atoms/pill-list/pill-list';
@import '../components/atoms/rich-text/rich-text';
@import '../components/atoms/themes/themes';
@import '../components/atoms/step-progress/step-progress';
@import '../components/atoms/unified-spacing/unified-spacing';
@import '../components/atoms/unified-spacing/unified-spacing-mixin';
@import '../components/atoms/skeleton/skeleton-market-selected';
@import '../components/atoms/bubble/bubble.base';
@import '../components/atoms/badge/badge.base';

/* -------------------- *\
	Molecules
\* -------------------- */
@import '../components/molecules/page-sector/page-sector';
@import '../components/molecules/page-intro/page-intro';
@import '../components/molecules/content-modules/content';
@import '../components/molecules/content-modules/content-btns';
@import '../components/molecules/content-modules/content-hdln';
@import '../components/molecules/image-list/image-list';
@import '../components/molecules/spinner/spinner';
@import '../components/molecules/tooltip/tooltip-base';
@import '../components/molecules/breadcrumbs/breadcrumbs-base';
@import '../components/molecules/video/video-base';
@import '../components/molecules/containers/tabs/tabs';
@import '../components/molecules/containers/tabs/tabs-offers';
@import '../components/molecules/teaser/offer/offer-tile.base';
@import '../components/molecules/teaser/tile.base';
@import '../components/molecules/teaser/lists/tile-list.base';
@import '../components/molecules/validity-indicator/validity-indicator';
@import '../components/molecules/tooltip-dialog/tooltip-dialog.base';
@import '../components/molecules/consent/consent';

/* -------------------- *\
Organisms
\* -------------------- */
@import '../components/organisms/site-header/site-header';
@import '../components/organisms/category-menu/category-menu';
@import '../components/organisms/jump-marks/jump-marks';
@import '../components/organisms/story-gallery/story-gallery';
@import '../components/organisms/podio-form/podio-form-spinner';
@import '../components/organisms/detail-block/detail_block_base';
@import '../components/organisms/category-menu/category-menu-base';
@import '../components/organisms/market-finder/market-finder.base';
@import '../components/organisms/market-finder/market-tile';
@import '../components/organisms/market-finder/market-list';
@import '../components/organisms/recipe-filter/recipe-filter.base';
@import '../components/organisms/category-bar/category-bar-base';

/* -------------------- *\
Edit Mode
\* -------------------- */
@import '../components/atoms/editor-message/editor-message';

/* -------------------- *\
	Technical Debt
\* -------------------- */

@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';

html {
  height: 100vh;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  &.hide-scrollbar,
  &.hide-scrollbar body {
    overflow: clip !important;
    display: flow-root;
  }

  &.hide-scrollbar,
  &.hide-scrollbar .site-header {
    /* fix content and header shift when hiding scrollbar */
    padding-right: var(--scrollbar-width, 17px);
  }
}

body {
  font-size: rem-calc(15);

  @include mq('lg') {
    font-size: rem-calc(20);
  }

  &.layer-open {
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
  }

  & > img {
    @include visually-hidden();
  }

  .crm-form,
  .nl-form {
    opacity: 0;
  }

  .tooltip {
    visibility: hidden;
  }

  .img-contain {
    width: auto;
  }
}

img[data-lazy-src] {
  will-change: contents;
}

dialog {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
  z-index: -1;
}
