@import 'scss-mixins-functions-variables/scss/layout/visually-hidden-mixins';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import '../../../subatoms/color/color_functions';
@import '../../../subatoms/vertical-spacing/vertical-spacing_functions';

.tabs {
  --tabs-section-width: #{rem-calc(400)};
  --tabs-border-radius: #{rem-calc(10)};
  --tabs-group-spacing: #{spacing('0.25')};

  @include mq('lg') {
    --tabs-section-width: #{rem-calc(550)};
  }

  &__selection-area {
    width: 100%;
    margin: 0 auto;

    @include mq('lg') {
      max-width: var(--tabs-section-width);
      width: 50%;
      margin: 0;
    }
  }

  &__content {
    //hide all tabs content
    @include visually-hidden();

    &--edit-mode {
      @include visually-hidden-destroy();
      overflow: visible;
    }
  }

  [type="radio"] {
    @include visually-hidden(); //hide all radio buttons, but keep them accessible

    &:disabled {
      display: none; //remove item from accessibility tree
    }

    @for $i from 1 through 2 {
      &:nth-child(#{$i} of .tab-input--offers):checked {
        ~ .tabs__content-area .tabs__content--offers-handout:nth-of-type(#{$i}) {
          //show only the tab content that corresponds to the checked input
          @include visually-hidden-destroy();
          overflow: visible;
        }
      }

      &:nth-child(#{$i} of .tab-input--weeks):checked {
        ~ .tabs__content-area .tabs__content--weeks .tabs__content:nth-of-type(#{$i}) {
          //show only the tab content that corresponds to the checked input
          @include visually-hidden-destroy();
          overflow: visible;
        }
      }

      &:nth-child(#{$i} of .tab-input--weeks):disabled {
        ~ .tabs__selection-area .tabs__item--weeks:nth-of-type(#{$i}) {
          background-color: transparent; //highlight the selected tab based on the checked input
          color: hsl(var(--grey-midnight));
          box-shadow: none;
          opacity: 0.32;
          pointer-events: none;
          cursor: default;
        }
      }
    }
  }

  &__item {
    display: block;
    margin-bottom: 0;

    &.tabs__item--weeks:only-of-type {
      display: none;
    }
  }
}
