@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/math/rounding-functions';
@import 'scss-mixins-functions-variables/scss/aspect-ratio/aspect-ratio-mixins';
@import '../../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../../subatoms/typography/typography_variables';
@import '../../../subatoms/color/color_functions';
@import '../../../subatoms/elevation/elevation_variables';

.offer-tile {
  --offer-margin-right: #{spacing('1')};
  --offer-margin-bottom: #{spacing('2')};
  --offer-margin-left: #{spacing('1')};
  --offer-padding-bottom: #{spacing('2')};
  --offer-image-aspect-ratio: 1;
  --offer-header-min-height: #{rem-calc(60)};
  --offer-header-gap: #{spacing('2')};
  --offer-header-margin-bottom: #{rem-calc(-18)};
  --offer-header-border-radius: #{rem-calc(10)};

  display: grid;
  grid-template-areas:
    "offer-header offer-header"
    "offer-image offer-image"
    "offer-info offer-info";
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding-bottom: var(--offer-padding-bottom);

  @include mq('sm') {
    --offer-header-gap: #{rem-calc(4)};
  }

  @include mq('lg') {
    --offer-margin-left: #{spacing('2')};
    --offer-margin-right: #{spacing('2')};
    --offer-padding-bottom: #{spacing('4')};
    --offer-header-min-height: #{rem-calc(80)};
  }

  [data-brand-category='true'] & {
    padding-top: spacing('8');

    @include mq('lg') {
      padding-top: spacing('10');
    }

    .offer-tile__image {
      height: auto;
    }
  }

  &__link {
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-start;
    background-color: hsl(var(--white));
    border-radius: rem-calc(10);
    color: hsl(var(--grey-stone));
    font-weight: $typography-weight-normal;
    text-decoration: none;
    transition: box-shadow 0.2s linear;

    &--edit {
      pointer-events: none;
    }

    @include mq('lg') {
      &:hover {
        box-shadow: map-get($elevations, '16_dp');
      }
    }
  }

  &__header {
    grid-area: offer-header;
    display: flex;
    justify-content: space-between;
    gap: 0;
    margin-bottom: var(--offer-header-margin-bottom);
    border-top-left-radius: var(--offer-header-border-radius);
    border-top-right-radius: var(--offer-header-border-radius);
    overflow: hidden;

    @include mq('sm') {
      gap: var(--offer-header-gap);
      justify-content: flex-start;
    }

    // Offer header styling when price bubble isn't present
    &:not(:has(.bubble)) {
      --offer-header-margin-bottom: 0;
      min-height: var(--offer-header-min-height);
    }

    // Offer header styling when price bubble is secondary and has small/medium size
    &:has(.bubble--secondary.bubble--sm, .bubble--secondary.bubble--md, .badge--icon-app) {
      justify-content: space-between;
    }

    // Offer header styling when price bubble has medium size
    &:has(.bubble--md) {
      @include mq('lg') {
        --offer-header-margin-bottom: #{rem-calc(-32)};
      }
    }

    .badge {
      align-self: flex-start;
      margin-top: spacing('3');
      margin-right: rem-calc(6);

      &--icon-app {
        margin-top: rem-calc(6);
        margin-right: rem-calc(6);
      }
    }
  }

  &__badges {
    justify-content: center;
    margin-bottom: spacing('1.5');
  }

  &__image {
    height: 100%;
    object-fit: contain;

    &-container {
      grid-area: offer-image;
      margin: 0 var(--offer-margin-right) var(--offer-margin-bottom) var(--offer-margin-left);
      aspect-ratio: var(--offer-image-aspect-ratio);
    }
  }

  &__info-container {
    grid-area: offer-info;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    margin-inline: var(--offer-margin-left) var(--offer-margin-right);
  }

  // Offer tile highlight
  &--highlight {
    --offer-margin-bottom: 0;
    --offer-image-aspect-ratio: auto;
    grid-template-areas:
        "offer-header offer-header"
        "offer-image offer-info";

    .offer-tile {
      &__header {
        gap: var(--offer-header-gap);
        justify-content: flex-start;
      }

      &__badges {
        justify-content: flex-start;
      }

      &__info-container {
        --offer-margin-left: 0;
        text-align: left;
        align-items: unset;
      }
    }
  }

  &__labels {
    display: flex;
  }

  &__headline {
    font: $typography-weight-medium #{rem-calc(14)} / decimal-round(calc(16 / 14), 3) $typography-family-akko;
    margin: 0;
    text-transform: none;
    color: hsl(var(--grey-midnight));

    @include mq('lg') {
      font-size: rem-calc(16);
    }
  }

  &__unit-price,
  &__origin {
    font-weight: $typography-weight-semi-bold;
    font-size: rem-calc(11);
    line-height: 1.36;
    margin-top: spacing('0.5');

    @include mq('lg') {
      margin-top: spacing('1');
      font-size: rem-calc(13);
      line-height: 1.18;
    }
  }

  &__origin {
    align-items: center;
    display: inline-flex;

    .icon {
      margin-right: 0.5em;
    }
  }

  &__info-text {
    font-size: rem-calc(9);
    line-height: normal;
    font-weight: $typography-weight-semi-bold;
    margin-top: rem-calc(6);

    @include mq('lg') {
      font-size: rem-calc(12);
      margin-top: rem-calc(10);
    }
  }
}
