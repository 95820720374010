@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/aspect-ratio/aspect-ratio-mixins';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/typography/typography_variables';
@import '../../subatoms/elevation/elevation_variables';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/z-indexes/z-indexes_functions';
@import '../../subatoms/grid/grid_variables';

.category-menu {
  font-family: $typography-family-akko;

  &__header {
    color: hsl(var(--red));
    font-size: rem-calc(14);
    font-weight: $typography-weight-bold;
    letter-spacing: rem-calc(0.14);
    line-height: calc(16 / 14);
  }

  &__header-wrapper {
    background-color: hsl(var(--white));
    box-shadow: map-get($elevations, '05_dp');
  }

  &__header-week {
    color: hsl(var(--grey-stone));
  }

  &__period-info {
    &.swiper-slide {
      font: $typography-weight-semi-bold #{rem-calc(13)} / 1.27 $typography-family-sans;
      color: hsl(var(--grey-midnight));
      background-color: hsl(var(--grey-wild-sand));
      border-radius: rem-calc(8);
    }
  }

  &__wrapper {
    align-items: center;
    background: hsl(var(--white));
    display: none;
    flex-direction: column;
    height: 0;
    width: 100%;

    &.swiper {
      display: none;
    }


    @include mq('lg') {
      box-shadow: map-get($elevations, 'category_menu');
      height: auto;
      left: 0;
      position: absolute;
      top: 100%;
    }

    .category-menu--flyout-open & {
      display: flex;
      height: 100%;

      @include mq('lg') {
        height: auto;
      }
    }
  }

  &__button-toggle {
    visibility: visible;
    z-index: 10;
    transition: box-shadow 0.1s;

    @include mq($max-width: 'md') {
      background-color: hsl(var(--white));
      color: hsl(var(--red));
      box-shadow: map-get($elevations, '08_dp_b_4');
    }

    .category-menu--flyout-open & {
      animation: scale-up 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;

      @include mq('lg') {
        animation: none;
      }
    }

    .icon {
      width: rem-calc(24);
      height: rem-calc(24);
    }

    @keyframes scale-up {
      0% {
        filter: blur(0);
        opacity: 1;
      }
      25% {
        filter: blur(1px);
      }
      50% {
        filter: blur(3px);
      }
      75% {
        filter: blur(0);
      }
      100% {
        filter: blur(0);
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
      }
    }
  }

  &__groups {
    height: 100%;
    padding-bottom: rem-calc(15);

    @include mq('lg') {
      padding-bottom: rem-calc(32);
    }
  }

  &__group {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    .pill-list {
      $offset-mobile: rem-calc(24);

      max-height: 100%;
      overflow-y: auto;
      padding-bottom: 8rem;
      padding-top: rem-calc(22);

      @include mq($max-width: 'md') {
        margin-left: $offset-mobile * -1;
        margin-right: $offset-mobile * -1;

        &__item {
          padding-left: $offset-mobile;
          padding-right: $offset-mobile;
        }
      }

      @include mq('lg') {
        overflow-y: visible;
        padding-bottom: 0;
        padding-top: rem-calc(30);
      }
    }

    @include mq('lg') {
      overflow: visible;
    }
  }

  &__pill {
    color: hsl(var(--grey-midnight));
    width: 100%;

    @include mq('md') {
      margin: rem-calc(8) 0;
    }

    @include mq('lg') {
      margin: 0;
      width: auto;
    }

    & > * {
      pointer-events: none;
    }
  }

  &__button-close {
    background: hsl(var(--white));
    border-radius: 50%;
    bottom: rem-calc(40);
    box-shadow: map-get($elevations, '08_dp_b_2');
    color: hsl(var(--red));
    display: none;
    font-size: rem-calc(18);
    margin-top: rem-calc(-95);
    padding: rem-calc(19);
    position: absolute;
    z-index: 2;

    @include mq('md') {
      margin-top: rem-calc(-85);
    }

    @include mq('lg') {
      display: none;
    }

    .category-menu--flyout-open & {
      display: flex;

      @include mq('lg') {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
  }

  @keyframes scale-in-top {
    0% {
      filter: blur(2px);
      height: 30%;
      opacity: 0;
      transform: scale(0);
    }
    25% {
      filter: blur(1px);
    }
    50% {
      filter: blur(0);
    }
    100% {
      height: 100%;
      opacity: 1;
      transform: scale(1);
    }
  }
}
