@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/grid/_grid_mixins';
@import '../../atoms/unified-spacing/unified-spacing-mixin';

$breadcrumbs-typography-size: rem-calc(11);
$breadcrumbs-typography-size-desktop: rem-calc(14);

$breadcrumbs-last-item-typography-size: rem-calc(13);
$breadcrumbs-last-item-typography-size-desktop: rem-calc(15);

.l-row--breadcrumb {
  justify-content: flex-end;

  > .l-col {
    @include mq('lg') {
      flex-basis: calc(100% - #{rem-calc(130)});
    }
  }
}

.breadcrumbs {
  margin: spacing('3') spacing('1') spacing('6') 0;
  font-size: $breadcrumbs-typography-size;

  @include mq('lg') {
    margin-right: 0;
    margin-bottom: spacing('10');
    font-size: $breadcrumbs-typography-size-desktop;
  }

  /* breadcrumbs positioned over image */
  &--over-image {
    position: absolute;
    top: rem-calc(44);
    right: rem-calc(14);
    z-index: 1;
    margin: 0;
    padding: spacing('0.5') spacing('1');
    white-space: nowrap;

    @include mq($max-width: 'sm') {
      max-width: 90vw;
    }

    @include mq('lg') {
      top: rem-calc(100);
      right: rem-calc(34);
    }

    .breadcrumbs {
      &__list {
        &-item {
          &:last-child {
            @include mq($max-width: 'sm') {
              max-width: rem-calc(200);
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

  }

  &--right-slim {
    right: 0;
    margin-bottom: 0;
    @include vertical-padding(bottom, 's');

    @include mq('lg') {
      right: 0;
    }

    .breadcrumbs {
      &__list {
        justify-content: flex-end;
      }
    }
  }

  &__list {
    display: flex;
    margin: 0;
    align-items: center;

    &-item {
      color: inherit;
      text-decoration: none;

      &:last-child {
        font-size: $breadcrumbs-last-item-typography-size;

        @include mq('lg') {
          font-size: $breadcrumbs-last-item-typography-size-desktop;
        }

      }
    }
    &-separator {
      padding: 0 spacing('0.5');
      font-size: rem-calc(11);
    }
  }
}
