@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';

.tooltip {
  &__container {
    align-items: center;
    display: inline-flex;
    position: relative;
  }

  &__icon {
    padding-right: rem-calc(25);
  }

  &__title {
    margin: 0;
    line-height: calc(20 / 15);
  }

  &__info-button {
    display: flex;
    padding: rem-calc(8);
  }

  &__info-icon {
    height: rem-calc(16);
    width: rem-calc(16);
  }
}
