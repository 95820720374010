@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';

.content-btns {
  margin-top: spacing('4');

  @include mq('md') {
    margin-top: spacing('8');
  }

  &:first-child {
    margin-top: 0;
  }

  &__block {
    margin-top: spacing('1');
    position: relative;

    @include mq('md') {
      margin-top: spacing('2');
    }

    &:first-child {
      margin-top: 0;
    }

    &--left {
      margin-left: initial;
      margin-right: auto;
    }

    &--right {
      margin-left: auto;
      margin-right: initial;
    }

    &:last-child {
      .btn {
        margin-bottom: 0;
      }
    }
  }

  &__media {
    margin-top: spacing('3');
  }

  &__underlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
