@mixin button-active($radius) {
  &:before {
    content:'';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: hsl(var(--black) / 0.48);
    border-radius: $radius;
    transform: translate(-50%, -50%);
  }

  &:active {
    box-shadow: none;
    overflow: hidden;

    &:before {
      transition: all 0.5s ease-out;
      opacity: 0;
      width: 150%;
      height: 150%;
    }
  }
}

@mixin button-focus-blue-outline() {
  outline: none;
  overflow: visible;

  &:after {
    content: '';
    position: absolute;
    inset: rem-calc(-3);
    border-radius: inherit;
    box-shadow: 0 0 0 rem-calc(1) hsl(var(--white)), 0 0 0 rem-calc(5) hsl(var(--blue-denim));
  }
}
