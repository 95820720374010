@mixin ellipsis($lines-number) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-number;
  overflow: hidden;
}

.ellipsis {
  @include ellipsis(2);
}
