@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import '../../subatoms/color/color_functions';

$skeleton-initial-opacity: 0.5;
$skeleton-final-opacity: 0.9;
$skeleton-border-radius: rem-calc(2);
$skeleton-background-color: hsl(var(--grey-midnight));

.skeleton {
  opacity: $skeleton-initial-opacity;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background-color: $skeleton-background-color;
    border-radius: $skeleton-border-radius;
    animation: skeleton-animation 1s linear infinite alternate;
  }

  &--text {
    display: block;
    width: 100%;
    line-height: 1;
    margin: 0 0 rem-calc(12);
    text-indent: rem-calc(-99999);
    overflow: hidden;
    text-align: left !important;
    svg {
      visibility: hidden;
    }
  }

  &--svg {
    align-self: flex-start;
    font-size: inherit !important;
    margin-top: 0 !important;
    use {
      visibility: hidden;
    }
  }

  &--image {
    img {
      visibility: hidden;
    }
  }

  &--display-flex {
    display: flex !important;
  }

  &--display-none {
    display: none !important;
  }

  &--flex-basis-100 {
    flex-basis: 100% !important;
  }

  &--flex-1 {
    flex: 1 !important;
  }

  &--content-fit {
    margin: 0 auto;
    max-width: rem-calc(300);
    @include mq('lg') {
      max-width: rem-calc(510);
    }
  }
}

@keyframes skeleton-animation {
  0% {
    opacity: $skeleton-initial-opacity;
  }
  100% {
    opacity: $skeleton-final-opacity;
  }
}
