@import '../../subatoms/color/color_functions';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/typography/typography_variables';
@import '../../subatoms/elevation/elevation_variables';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';

$button-width: rem-calc(229);
$button-height: rem-calc(42);
$button-typography-size: rem-calc(15);
$button-typography-weight: $typography-weight-medium;
$button-typography-line-height: calc(24 / 15);
$button-typography-color: hsl(var(--white));
$button-typography-background-color: hsl(var(--grey-midnight));
$button-padding: rem-calc(9) rem-calc(20);
$button-margin-top: spacing('5');
$button-margin-top-desktop: spacing('10');
$button-border-radius: rem-calc(21);
$button-shadow: map-get($elevations, '08_dp_b_3');

$button-themes: (
  'red': (
    'background': hsl(var(--red)),
    'typography': hsl(var(--white)),
  ),
);

.link-button {
  background-color: $button-typography-background-color;
  border-radius: $button-border-radius;
  box-shadow: $button-shadow;
  color: $button-typography-color;
  display: inline-block;
  font-size: $button-typography-size;
  font-weight: $button-typography-weight;
  line-height: $button-typography-line-height;
  min-height: $button-height;
  min-width: $button-width;
  padding: $button-padding;
  text-align: center;
  text-decoration: none;

  @each $theme-name, $theme in $button-themes {
    &--#{$theme-name} {
      background: map-get($theme, 'background');
      color: map-get($theme, 'typography');
    }
  }
}
