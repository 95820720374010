@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../subatoms/grid/grid_variables';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/typography/typography_variables';

$media-height: rem-calc(537);
$media-height-tablet: rem-calc(473);
$media-height-mobile: rem-calc(468);
$gap: rem-calc(14);
$gap-tablet: rem-calc(8);

.story-gallery {
  /* story gallery slider */
  .carousel {
    background-color: hsl(var(--black-butchers));
    border-bottom-left-radius: rem-calc(15);
    border-bottom-right-radius: rem-calc(15);
    height: $media-height-mobile;
    position: relative;
    width: 100%;

    @include mq('md') {
      border-top-right-radius: rem-calc(15);
      border-bottom-left-radius: 0;
      height: $media-height-tablet;
      width: 50%;
    }

    @include mq('lg') {
      height: $media-height;
      width: 27.5%;
    }

    &__controls {
      display: flex;
      justify-content: space-between;
      margin: 0;
    }
    &__control {
      align-items: flex-start;
      border-radius: 0;
      box-shadow: none;
      color: hsl(var(--white));
      display: flex;
      flex-direction: column;
      font-size: rem-calc(35);
      height: 75%;
      justify-content: flex-end;
      left: 0;
      margin: 0;
      padding-bottom: rem-calc(88);
      position: absolute;
      top: 0;
      width: 25%;
      z-index: 1;

      @include mq('lg') {
        font-size: rem-calc(56);
        padding-bottom: rem-calc(97);
      }

      &:focus {
        outline: none;
      }

      &[disabled] {
        cursor: default;
        opacity: 0;
      }

      &--next {
        align-items: flex-end;
        left: auto;
        right: 0;
        svg {
          margin-right: rem-calc(-3);
          @include mq('lg') {
            margin-right: rem-calc(-10);
          }
        }
      }
      svg {
        filter: drop-shadow(0 2px 6px hsl(var(--black) / 0.61));
        margin-left: rem-calc(-3);
        @include mq('lg') {
          filter: drop-shadow(0 3px 9px hsl(var(--black) / 0.61));
          margin-left: rem-calc(-10);
        }
      }
    }

    &__indicators {
      bottom: 0;
      display: flex;
      justify-content: space-between;
      left: 0;
      margin: 0;
      padding: spacing('2');
      position: absolute;
      width: 100%;
      z-index: 1;

      @include mq('md') {
        bottom: rem-calc(10);
      }

      li {
        flex: 1;
        margin-right: rem-calc(5);
        &:first-child {
          .carousel__indicator[aria-selected='true']::before {
            content: none;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }

      .carousel__indicator {
        background-color: hsl(var(--white) / 0.25);
        border-radius: rem-calc(4);
        height: rem-calc(4);
        pointer-events: none;
        width: 100%;

        @include mq('lg') {
          height: rem-calc(7);
        }

        &::before {
          opacity: 0;
        }

        &[aria-selected='true'],
        &.carousel__indicator--visited {
          background-color: hsl(var(--white));
          &::before {
            bottom: rem-calc(35);
            color: hsl(var(--white));
            content: attr(data-name);
            font: $typography-weight-extra-bold #{rem-calc(15)} / 1 $typography-family-sans;
            left: rem-calc(18);
            position: absolute;

            @include mq('lg') {
              font-size: rem-calc(20);
            }
          }
        }

        &[aria-selected='true'] {
          &::before {
            opacity: 1;
          }
        }

        &.carousel__indicator--visited {
          opacity: 1;
        }

        &--progress {
          &[aria-selected='false'] {
            progress {
              display: none;
            }
          }

          &[aria-selected='true'],
          &.carousel__indicator--visited {
            background-color: hsl(var(--white) / 0.25);

            progress {
              appearance: none;
              background-color: hsl(var(--white) / 0.25);
              border-radius: rem-calc(4);
              color: hsl(var(--white));
              display: block;
              height: 100%;
              width: 100%;

              &::-webkit-progress-bar {
                background-color: transparent;
              }

              &::-webkit-progress-value {
                background: hsl(var(--white));
                border-radius: rem-calc(4);
              }

              &::-moz-progress-bar {
                background-color: hsl(var(--white));
                border: none;
                border-radius: rem-calc(4);
              }
            }
          }
        }
      }
    }

    &__slides-wrapper,
    &__slides,
    &__slide {
      height: 100%;
    }

    &__slides {
      transition: none;
    }

    &__slide {
      position: relative;
      &::before {
        background-image: linear-gradient(to bottom, hsl(var(--white) / 0), hsl(var(--black) / 0.1), hsl(var(--black) / 0.61));
        bottom: 0;
        content: '';
        height: rem-calc(100);
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
      }
    }

    .video-player {
      height: 100%;
      max-height: calc(100vh - 6.25rem);
      min-height: $media-height-mobile;
      width: 100%;

      @include mq('md') {
        min-height: $media-height-tablet;
      }
      @include mq('lg') {
        min-height: $media-height-mobile;
      }
      &.cover {
        object-fit: cover;
      }
      &.fullHeight {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        width: auto;
      }
      &.animate {
        ~ .btn--primary svg {
          animation: linear infinite alternate;
          animation-duration: 1.7s;
          animation-name: wiggle;
        }
        ~ .video-controls [data-state='pause'] {
          .btn-play {
            opacity: 1;
            transition: all 0.3s linear;
            visibility: visible;
          }
        }
        @keyframes wiggle {
          0% {
            opacity: 0.1;
            right: rem-calc(-5);
          }
          50% {
            opacity: 1;
            right: rem-calc(5);
          }
          100% {
            opacity: 0.1;
            right: rem-calc(-5);
          }
        }
      }
    }

    .btn--primary {
      bottom: rem-calc(55);
      font: $typography-weight-bold #{rem-calc(13)} / 1 $typography-family-sans;
      margin: 0;
      padding: rem-calc(11.5) rem-calc(32) rem-calc(11.5) rem-calc(19);
      position: absolute;
      right: rem-calc(12);
      z-index: 1;
      @include mq('lg') {
        bottom: rem-calc(70);
        font-size: rem-calc(16);
        padding: rem-calc(16) rem-calc(48) rem-calc(16) rem-calc(30);
      }

      svg {
        font-size: rem-calc(24);
        margin-right: 0;
        position: absolute;
        right: spacing('1');
        top: 50%;
        transform: translate(0, -50%);

        @include mq('lg') {
          font-size: rem-calc(32);
        }
      }
    }

    &--edit {
      .carousel__slide {
        pointer-events: all;

        img,
        video {
          pointer-events: none;
        }
      }
    }
  }

  .video-controls {
    button {
      background: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }

    /* Play button */
    .play-wrapper {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translate(-50%, 0);
      width: 50%;

      .btn-play {
        align-items: center;
        background-color: hsl(var(--white));
        border-radius: 50%;
        color: hsl(var(--red));
        display: flex;
        font-size: rem-calc(32);
        height: rem-calc(50);
        justify-content: center;
        opacity: 0;
        position: relative;
        transition: all 0.2s ease-in-out;
        visibility: hidden;
        width: rem-calc(50);
      }
      &:hover:not([data-state='play']) {
        .btn-play {
          opacity: 1;
          transition: all 0.3s linear;
          visibility: visible;
        }
      }
    }

    /* Sound on and off */
    .sound-wrapper {
      bottom: rem-calc(55);
      font-size: rem-calc(32);
      left: rem-calc(17);
      line-height: 1;
      position: absolute;
      color: hsl(var(--white));

      @include mq('lg') {
        bottom: rem-calc(70);
      }
      &[data-state='sound-off'] {
        .btn-sound-on {
          display: none;
        }
      }
      &[data-state='sound-on'] {
        .btn-sound-off {
          display: none;
        }
      }
      svg {
        filter: drop-shadow(0 3px 5px hsl(var(--black) / 0.5));
        @include mq('md') {
          filter: drop-shadow(0 2px 4px hsl(var(--black) / 0.5));
        }
      }
    }
  }

  &.images-1,
  &.images-2 {
    @include mq('md') {
      .carousel,
      .story-gallery__images-wrapper {
        width: 50%;
      }
      .story-gallery__desc-items {
        flex: 0 0 50%;
      }
    }
    @include mq('lg') {
      .carousel {
        width: 27.5%;
      }
      .story-gallery__images-wrapper {
        width: 72.5%;
      }
      .story-gallery__image-item {
        &:nth-child(1) {
          flex-basis: 79%;
        }
      }
      .story-gallery__desc-items {
        flex: 0 0 62%;
      }
    }
  }

  &.images-1 {
    @include mq('lg') {
      .story-gallery__image-item {
        &:nth-child(1) {
          flex-basis: 100%;
        }
      }
    }
  }

  &.images-3 {
    @include mq('md') {
      .carousel,
      .story-gallery__images-wrapper {
        width: 50%;
      }
      .story-gallery__desc-items {
        flex: 0 0 50%;
      }
    }
    @include mq('lg') {
      .carousel {
        width: 27.5%;
      }
      .story-gallery__images-wrapper {
        width: 72.5%;
      }
      .story-gallery__image-item {
        &:nth-child(2),
        &:nth-child(3) {
          height: 100%;
          margin-left: rem-calc(14);
        }
        &:nth-child(2) {
          flex-basis: 27%;

          &::after,
          img {
            border-bottom-right-radius: rem-calc(15);
          }
        }
      }

      .story-gallery__desc-items {
        flex: 0 0 67%;
      }
      .ingr-button {
        flex: 0;
      }
    }
  }

  &.images-4 {
    @include mq('md') {
      .story-gallery__desc-items {
        flex: 0 0 50%;
      }
    }
    @include mq('lg') {
      .story-gallery__desc-items {
        flex: 0 0 57.5%;
      }
      .ingr-button {
        flex: 0;
      }
    }
  }

  /* story gallery mood images */
  &__images-wrapper {
    display: none;

    @include mq('md') {
      display: flex;
      flex-wrap: wrap;
      height: $media-height-tablet;
      position: relative;
      width: 50%;
      z-index: 0;
    }

    @include mq('lg') {
      height: $media-height;
      width: 72.5%;
    }

    &.images-3,
    &.images-2 {
      flex-wrap: nowrap;
    }

    &--inactive {
      > * {
        &::after {
          background-color: black;
          border-radius: rem-calc(15);
          bottom: 0;
          content: '';
          left: 0;
          opacity: 0.3;
          position: absolute;
          right: 0;
          top: 0;
          transition: opacity 0.3s linear;
          z-index: 1;
        }
      }
    }
    img {
      border-radius: rem-calc(15);
    }
  }

  &__image-item {
    flex-basis: calc(50% - #{$gap-tablet});
    height: calc(50% - #{$gap-tablet} / 2);
    margin-left: $gap-tablet;
    position: relative;

    @include mq('lg') {
      flex: 1 1 28%;
      height: 100%;
      margin-left: $gap;
    }

    &:last-child {
      &::after,
      img {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      flex-basis: calc(50% - #{$gap-tablet});
      height: calc(50% - #{$gap-tablet} / 2);
      @include mq('lg') {
        flex-basis: 8.25%;
        height: calc(50% - (#{$gap} / 2));
      }
    }

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: $gap-tablet;
      @include mq('lg') {
        margin-bottom: 0;
      }
    }

    &:nth-child(3) {
      align-self: auto;
      margin-left: $gap-tablet;

      @include mq('lg') {
        align-self: flex-end;
        margin-left: -19%;
      }
    }

    .images-1 & {
      flex-basis: 100%;
      height: 100%;
    }

    .images-2 & {
      flex-basis: 50%;
      height: 100%;
    }

    .images-3 & {
      flex-basis: 50%;

      &:nth-child(1) {
        height: 100%;
        margin-bottom: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        height: calc(50% - #{$gap-tablet} / 2);
        @include mq('lg') {
          flex-basis: 50%;
          height: calc(50% - #{$gap} / 2);
        }
      }
      &:nth-child(2) {
        &::after,
        img {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
      &:nth-child(3) {
        align-self: flex-end;
        margin-left: calc(-50% + #{$gap-tablet});
        @include mq('lg') {
          margin-left: calc(-50% + #{$gap});
        }

        &::after,
        img {
          @include mq('md') {
            border-top-right-radius: 0;
          }
        }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          flex-basis: 49%;
        }
      }
    }

    .images-4 & {
      @include mq($max-width: 'md') {
        &:nth-child(2) {
          &::after,
          img {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
        &:last-child {
          &::after,
          img {
            border-top-right-radius: 0;
          }
        }
      }
    }
  }

  img {
    font-family: 'background-size: cover; '; // for polyfill
    height: 100%;
    object-fit: cover;
    &.fullHeight {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      width: auto;
    }
  }

  /* story gallery description */
  &__media {
    background-color: hsl(var(--white));
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    & + .page-sector {
      display: flex;
      padding-top: spacing('7');

      @include mq('lg') {
        padding-top: spacing('12');
      }
    }

    &--expanded {
      background-color: hsl(var(--black-butchers));
      border-radius: rem-calc(15);

      & + .page-sector {
        padding-top: spacing('12');

        @include mq('lg') {
          padding-top: spacing('15');
        }
      }
    }
  }
  &__desc {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    @include mq('md') {
      align-items: flex-start;
      flex-direction: row;
    }

    .ingr-button {
      display: none;
      padding: rem-calc(16) rem-calc(16) rem-calc(96);

      @include mq('md') {
        flex: 1;
        justify-content: center;
      }

      @include mq('lg') {
        padding: rem-calc(32) 0;
      }
    }

    &--expanded {
      .story-gallery {
        &__desc-item {
          max-height: 100%;
          padding-bottom: rem-calc(32);
          padding-top: rem-calc(16);

          @include mq('md') {
            padding-bottom: rem-calc(96);
          }

          @include mq('lg') {
            padding-bottom: rem-calc(120);
            padding-top: rem-calc(32);
          }
          h1 {
            margin-top: 0;
          }

          p {
            margin: 0;
          }
        }
      }

      .ingr-button {
        display: flex;
      }
    }

    &-items {
      width: 100%;
      @include mq('lg') {
        padding-right: rem-calc(150);
      }
    }

    &-item {
      color: hsl(var(--white));
      display: flex;
      flex-wrap: wrap;
      font: $typography-weight-semi-bold #{rem-calc(15)} / 1.4 $typography-family-sans;
      justify-content: flex-start;
      letter-spacing: normal;
      max-height: 0;
      opacity: 0;
      padding-left: rem-calc(16);
      padding-right: rem-calc(16);
      position: absolute;
      transition: opacity 0.2s linear, padding-top 0.3s linear, padding-bottom 0.3s linear, max-height 0.3s linear;
      z-index: -1;

      @include mq('lg') {
        font-size: rem-calc(20);
        letter-spacing: rem-calc(-0.24);
      }

      &--active {
        max-height: 100%;
        opacity: 1;
        padding-bottom: rem-calc(32);
        padding-top: rem-calc(16);
        position: relative;
        z-index: auto;

        @include mq('md') {
          padding-bottom: rem-calc(96);
        }

        @include mq('lg') {
          padding-bottom: rem-calc(120);
          padding-top: rem-calc(32);
        }
      }
    }
  }

  &--edit {
    flex-direction: column;

    .carousel {
      &__slides {
        flex-direction: row-reverse;
        flex-wrap: wrap;
      }

      &__slide {
        display: inline-block;
        height: auto;
      }
    }

    .story-gallery {
      &__desc {
        flex-direction: column;

        &-item {
          display: block;
          max-height: 100%;
          opacity: 1;
          position: static;
          &--inside {
            color: #000;
          }
        }
      }
    }

    .recipe_steps {
      img,
      video {
        max-width: rem-calc(250);
        &.fullHeight {
          height: auto;
        }
      }
    }
  }

  .page-sector {
    & > div {
      width: 100%;
    }

    &__head {
      padding-bottom: 0;
      flex-direction: column;
    }

    &__hdln {
      margin-bottom: spacing('2');
      @include mq('lg') {
        margin-bottom: spacing('1');
      }
    }

    &__text {
      font-weight: $typography-weight-semi-bold;
    }
  }
}
