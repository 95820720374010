@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';

.attribute {
  &__item {
    display: flex;
    flex-direction: column;
    padding: 0 rem-calc(15);
  }

  &__icon {
    height: rem-calc(56);
    margin: 0 auto;
    width: rem-calc(56);
  }

  &__label {
    font-size: rem-calc(11);
    font-weight: $typography-weight-normal;
    line-height: calc(20 / 11);
    text-align: center;

    @include mq('lg') {
      font-size: rem-calc(15);
    }
  }
}
