@import '../../subatoms/color/color_functions';

.rich-text {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &__link {
    color: hsl(var(--grey-midnight));

    &:hover {
      color: hsl(var(--red));
    }
  }
}
