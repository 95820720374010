@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';

.podio-form {
  &.spinner-active {
    #momentumtools-form {
      opacity: 0;
    }

    .spinner {
      display: block;
    }
  }

  .spinner {
    display: none;
    font-size: rem-calc(96);
    left: 50%;
    position: absolute;

    &__container {
      position: relative;
    }

    &__spinner {
      height: 100%;
      opacity: 0;
      padding: 0.25rem;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(-500deg) scale(0.4);
      transition: 350ms;
      width: 100%;
    }

    .icon {
      transform: translateX(50%);
    }

    .spinner-active & {
      &__spinner {
        opacity: 1;
        transform: none;
      }
    }
  }
}
