$support-custom-properties-fallback: true !default;
$support-flex-grid-fallback: true !default;

$grid-column-classes: true !default;
$grid-row-classes: true !default;

$vertical-spacing-classes: true !default;

$breakpoints: (
  'xs': (
    'min-width': 0,
    'max-width': 575px,
    'gutter': 15px,
    'vertical-gutter': 24px,
    'columns': 12,
    'offset': 15px,
    'tiles': 2,
  ),
  'sm': (
    'min-width': 576px,
    'max-width': 767px,
    'tiles': 3,
  ),
  'md': (
    'min-width': 768px,
    'max-width': 959px,
    'offset': 30px,
    'tiles': 4,
  ),
  'lg': (
    'min-width': 960px,
    'max-width': 1199px,
    'gutter': 30px,
    'offset': 47px,
    'tiles': 4,
  ),
  'xl': (
    'min-width': 1200px,
    'max-width': 1590px,
    'vertical-gutter': 24px,
    'tiles': 5,
  ),
  'xxl': (
    'min-width': 1591px,
    'tiles': 6,
  ),
);

$max-width-narrow: 1024px !default;
$max-width: 1590px !default;
$max-width-extra-wide: 1920px !default;

// Variables used as fallbacks for css custom properties, grid mixins and
// such. Use the $breakpoints map for properly defining grid-relevant, responsive values.
// Note: give these variables default values here so that they need not be defined centrally
// if there is no need to override them.
$grid-fallback-columns: 0;
$grid-fallback-gutter: 0;
$grid-fallback-vertical-gutter: 0;
$grid-fallback-offset: 0;

// loop through the breakpoint map to find the initial (mobile-first) values for the grid
@each $breakpoint-name, $breakpoint-config in $breakpoints {
  @if map-has-key($breakpoint-config, 'columns') {
    $grid-fallback-columns: map-get($breakpoint-config, 'columns');
  }

  @if map-has-key($breakpoint-config, 'gutter') {
    $grid-fallback-gutter: map-get($breakpoint-config, 'gutter');
  }

  @if map-has-key($breakpoint-config, 'vertical-gutter') {
    $grid-fallback-vertical-gutter: map-get($breakpoint-config, 'vertical-gutter');
  }

  @if map-has-key($breakpoint-config, 'offset') {
    $grid-fallback-offset: map-get($breakpoint-config, 'offset');
  }
}
