@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/math/rounding-functions';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../subatoms/typography/typography_variables';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';

.tile {
  font-size: rem-calc(11);
  line-height: decimal-round(calc(13 / 11), 3);
  max-width: 100%;
  position: relative;

  @include mq('lg') {
    font-size: rem-calc(13);
  }

  &__hdln {
    font: $typography-weight-medium #{rem-calc(14)} / decimal-round(calc(16 / 14), 3) $typography-family-akko;
    margin: 0;
    text-transform: none;

    @include mq('lg') {
      font-size: rem-calc(16);
    }
  }

  &__link {
    &--cover {
      &::after {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  &__action {
    position: relative;

    &::after {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &--cover {
    .tile {
      &__image {
        object-fit: cover;
      }
    }
  }

  &--in-grid {
    .tsr {
      &__main {
        padding-bottom: spacing('1');
        padding-top: spacing('1');

        @include mq('lg') {
          padding-bottom: spacing('2');
          padding-top: spacing('2');
        }
      }

      &__hdln {
        margin-bottom: 0;
        margin-top: 0;
        text-transform: none;
      }

      &__body {
        flex-grow: 0;
      }

      &__footer {
        margin-top: auto;

        & > div {
          margin-top: spacing('2');
        }
      }
    }

    .rating {
      padding-top: spacing('2');
      margin-top: auto;

      &__stars {
        --column-gap: #{rem-calc(4)};
      }

      &__svg {
        font-size: rem-calc(13);

        @include mq('lg') {
          font-size: rem-calc(18);
        }
      }

      & + .tsr__footer {
        margin-top: 0;
      }

      &:last-child {
        padding-bottom: rem-calc(31);
      }
    }

    [data-recipe-slug] {
      margin-top: auto;

      .rating {
        padding-bottom: 0;
      }

      & + .tsr__footer {
        margin-top: 0;
      }
    }
  }
}
