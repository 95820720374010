@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import './font_face_mixin';
@import './typography_variables';

/* -------------------- *\
	AkkoForPenny
\* -------------------- */

@include font-face(
  $typography-family-akko-name,
  '../fonts/Akko/AkkoForPenny-Regular',
  $typography-weight-normal,
  normal,
  woff2,
  'AkkoForPenny-Regular'
);

@include font-face(
  $typography-family-akko-name,
  '../fonts/Akko/AkkoForPenny-Bold',
  $typography-weight-bold,
  normal,
  woff2,
  'AkkoForPenny-Bold'
);

@include font-face(
  $typography-family-akko-name,
  '../fonts/Akko/AkkoForPenny-BoldItalic',
  $typography-weight-bold,
  italic,
  woff2,
  'AkkoForPenny-BoldItalic'
);

@include font-face(
  $typography-family-akko-name,
  '../fonts/Akko/AkkoForPenny-Black',
  $typography-weight-black,
  normal,
  woff2,
  'AkkoForPenny-Black'
);

@include font-face(
  $typography-family-akko-name,
  '../fonts/Akko/AkkoForPenny-Medium',
  $typography-weight-medium,
  normal,
  woff2,
  'AkkoForPenny-Medium'
);

/* -------------------- *\
	OpenSans
\* -------------------- */

@include font-face(
  $typography-family-sans-name,
  '../fonts/OpenSansVariable/OpenSans-VariableFont',
  $typography-weight-hairline $typography-weight-black,
  normal,
  woff2,
  'OpenSans-Regular',
  woff2-variations
);

@include font-face(
  $typography-family-sans-name,
  '../fonts/OpenSansVariable/OpenSans-Italic-VariableFont',
  $typography-weight-hairline $typography-weight-black,
  italic,
  woff2,
  'OpenSans-Italic',
  woff2-variations
);
