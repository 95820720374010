@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'unified-spacing-variables';

@mixin vertical-padding($position, $size) {
  $mobile-value: map-get(map-get($spacings, $size), 'mobile');
  $desktop-value: map-get(map-get($spacings, $size), 'desktop');

  padding-#{$position}: $mobile-value;

  @include mq('lg') {
    padding-#{$position}: $desktop-value;
  }
}

@mixin vertical-margin($position, $size, $signMultiplier: 1) {
  $mobile-value: map-get(map-get($spacings, $size), 'mobile');
  $desktop-value: map-get(map-get($spacings, $size), 'desktop');

  margin-#{$position}: $mobile-value * $signMultiplier;

  @include mq('lg') {
    margin-#{$position}: $desktop-value * $signMultiplier;
  }
}
