@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/grid/grid_variables';
@import '../../subatoms/z-indexes/z-indexes_functions';

//spacing
$category-spacing-vertical: spacing('1');
$category-spacing-horizontal: spacing('2');
$category-btn-top-spacing: spacing('1');
$category-btn-bottom-spacing: spacing('0.5');
$category-offset: rem-calc(15);

:root {
  @include mq('lg') {
    scroll-padding-top: var(--scroll-padding-top, 0);
  }
}

.category-menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: z('category-menu');

  @include mq('lg') {
    transition: none;
    top: calc(var(--sticky-header-height) - #{rem-calc(1)});
  }

  &.category-menu--flyout-open {
    background: hsl(var(--white));
    bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: z('category-menu-open');

    @include mq('lg') {
      bottom: auto;
      position: sticky;
      top: var(--sticky-header-height);
      z-index: z('category-menu');
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    @include mq($max-width: 'md') {
      padding: 0;
    }

    @include mq('lg') {
      min-height: rem-calc(76);
    }
  }

  &__header-wrapper {
    display: flex;
    width: 100%;
    z-index: 2;
  }

  &__button-toggle {
    position: absolute;
    display: flex;
    align-items: center;
    gap: spacing('1');
    min-width: auto;
    padding: rem-calc(10) spacing('3');
    margin: 0;
    cursor: pointer;

    @include mq($max-width: 'md') {
      top: calc(100% + #{rem-calc(32)});
    }

    @include mq('lg') {
      left: var(--offset);
    }
  }

  &__header-container {
    display: flex;
    position: relative;
    width: 100%;

    @include mq('lg') {
      max-width: calc(100% - #{rem-calc(207)});
      margin-left: auto;
    }

    @include mq('xl') {
      max-width: none;
      margin-left: 0;
    }
  }

  &__header-swiper {
    &.swiper {
      padding-right: $category-offset;
      @include mq('lg') {
        margin-inline: auto;
      }
    }
    .swiper-wrapper {
      display: flex;
      padding-top: $category-btn-top-spacing;
      position: relative;
    }
    .swiper-slide,
    .swiper-slide-init {
      width: auto;
      padding: spacing('2') 0 spacing('1') $category-offset;
      margin-top: $category-btn-bottom-spacing;
      height: calc(100% - #{$category-btn-top-spacing} - #{$category-btn-bottom-spacing});
      pointer-events: none;
    }
    .swiper-slide-init {
      @at-root .category-menu:not(.init) #{&} {
        display: none;
      }
    }
    .btn {
      min-width: auto;
      padding: rem-calc(2) rem-calc(14);
      margin: 0;
      pointer-events: all;
    }
  }

  &__header-week {
    position: absolute;
    top: 0;
    display: none;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: $category-btn-top-spacing;
    width: rem-calc(237);
    height: rem-calc(5);
    border-inline: rem-calc(1) solid currentcolor;

    @at-root .category-menu.init #{&} {
      display: none;
    }

    &[data-week='current'] {
      left: $category-offset;
    }

    &.active {
      display: flex;
    }

    &::before {
      content: '';
      position: absolute;
      top: rem-calc(-3);
      width: 100%;
      height: 100%;
      box-shadow: 0 rem-calc(1) 0 0 currentcolor;
    }
    &::after {
      content: attr(data-startend);
      position: absolute;
      top: rem-calc(-5);
      background-color: white;
      padding-inline: spacing('1');
      white-space: nowrap;
    }
  }

  &__period-info {
    &.swiper-slide {
      display: flex;
      gap: spacing('1');
      min-height: rem-calc(48);
      height: max-content;
      max-width: rem-calc(250);
      margin-bottom: spacing('2');
      margin-left: spacing('2.5');
      margin-right: var(--offset);
      padding: spacing('1');
    }

    .icon {
      width: rem-calc(16);
      height: rem-calc(16);
    }
  }

  &__category-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    @include mq('lg') {
      width: auto;
    }

    &:last-of-type {
      margin-bottom: -$category-spacing-vertical;
    }
  }

  & ~ [data-index="0"] {
    @include mq($max-width: 'md') {
      margin-top: spacing('2.5') * -1;
      padding-top: spacing('19');
    }
  }
}
