@import 'scss-mixins-functions-variables/scss/layout/visually-hidden-mixins';

.sp {
  &-container {
    display: flex;
  }

  &-step {
    flex: 100% 0 0;
    width: 100%;

    &:not(&--active) {
      @include visually-hidden;
    }
  }
}
