@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import '../../atoms/unified-spacing/unified-spacing-variables';

@mixin page-sector-spacing() {
  @each $spacing-name, $spacing-config in $spacings {
    $spacing-mobile: map-get($spacing-config, 'mobile');
    $spacing-desktop: map-get($spacing-config, 'desktop');

    .page-sector--spacing-above-#{$spacing-name} {
      padding-top: $spacing-mobile;

      @include mq('lg') {
        padding-top: $spacing-desktop;
      }
    }

    .page-sector--spacing-below-#{$spacing-name} {
      padding-bottom: $spacing-mobile;

      @include mq('lg') {
        padding-bottom: $spacing-desktop;
      }
    }
  }
}
