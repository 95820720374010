@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/grid/_grid_mixins';

.category-bar {
  --cat-bar-padding: #{spacing('1')};
  --cat-img-size: #{rem-calc(50)};
  --cat-img-margin-right: #{spacing('1')};

  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: rem-calc(10);
  padding: var(--cat-bar-padding);

  @include mq('lg') {
    --cat-bar-padding: #{spacing('1')} #{spacing('2')};
    --cat-img-size: #{rem-calc(84)};
    --cat-img-margin-right: #{spacing('2')};
  }

  &__img {
    height: var(--cat-img-size);
    width: var(--cat-img-size);
    object-fit: contain;
    margin-right: var(--cat-img-margin-right);
  }

  &__badges {
    display: flex;
  }

  &__btn {
    margin-bottom: 0;
    min-width: auto;

    @include mq($max-width: 'md') {
      color: hsl(var(--white));
      background: none;
    }

    &-label {
      display: none;

      @include mq('lg') {
        display: block;
        color: hsl(var(--red-thunderbird));
      }
    }
  }
}
