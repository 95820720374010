@import '../../subatoms/color/color_functions';
@import '../../subatoms/elevation/elevation_variables';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/typography/typography_variables';
@import '../../subatoms/grid/grid_mixins';
@import '../../subatoms/z-indexes/z-indexes_functions';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';

$nav-elevation-desktop: 0 5px 15px 0 rgba(145, 64, 64, 0.23);

.site-header {
  width: 100%;
  font-family: $typography-family-akko;

  &.hide-for-large {
    position: static;
  }

  &--sticky {
    position: relative;
    z-index: z('site-header');

    @include mq('lg') {
      position: sticky;
      top: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &__wrapper {
    background: hsl(var(--red));
    border-radius: 0 0 15px 15px;
    box-shadow: map-get($elevations, '08_dp');
    color: hsl(var(--white));
    padding-bottom: rem-calc(12);
    padding-top: rem-calc(12);
    position: relative;

    @include mq('lg') {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &__container {
    @include make-container();

    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
    flex: 0 1 50%;
    justify-content: flex-start;
    margin: 0;

    .logo {
      font-size: rem-calc(17);
      height: 1em;
      width: 5.18em;

      @include mq('lg') {
        font-size: rem-calc(27);
      }

      &__link {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      &__text {
        display: block;
        font-size: rem-calc(12);
        font-weight: $typography-weight-bold;
        letter-spacing: rem-calc(0.46);
        line-height: calc(17 / 12);

        @include mq('lg') {
          font-size: rem-calc(18);
          letter-spacing: rem-calc(0.69);
          line-height: calc(28 / 18);
        }
      }
    }
  }

  &__menu {
    display: flex;
    flex: 0 1 50%;
    justify-content: flex-end;
    margin-bottom: auto;
  }

  &__nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: z('site-header');

    @include mq('lg') {
      width: auto;
      position: static;
      padding: 0;
      border-radius: 0;
      display: flex;
      flex: 1 0 auto;
      background-color: transparent;
      box-shadow: none;
      color: hsl(var(--white));
    }
  }

  .main-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include mq('sm') {
      justify-content: center;
    }

    @include mq('lg') {
      width: auto;
      max-width: none;

      &.hovered {
        .sub-nav__list-wrapper {
          transition-duration: 0.1s;
          transition-delay: 0.1s;
        }
      }
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-inline: rem-calc(10);

      @include mq('sm') {
        padding-inline: rem-calc(15);
      }

      @include mq($max-width: 'md') {
        width: 100%;
        padding-block: rem-calc(4);
        border-radius: rem-calc(15) rem-calc(15) 0 0;
        background-color: hsl(var(--white));
        box-shadow: map_get($elevations, '08_dp');
      }
    }

    &__item {
      margin-inline: 0;

      @include mq('sm') {
        margin-inline: rem-calc(4);
      }

      @include mq('lg') {
        margin: 0;

        &.hovered-btn .nav-item__btn {
          background-color: hsl(var(--grey-midnight) / 0.2);
          border-radius: rem-calc(10);
        }
      }

      > .nav-item {
        &__link {
          @include mq($max-width: 'md') {
            color: hsl(var(--grey-midnight));
          }

          .site-header__icon {
            @include mq($max-width: 'md') {
              padding-bottom: 0;
            }
          }
        }
      }

      //submenu
      .sub-nav {
        display: none;

        &__list {
          display: flex;

          @include mq($max-width: 'md') {
            flex-direction: column;
            overflow-y: scroll;
            max-height: 100%;
          }

          @include mq('lg') {
            @include make-container();
            margin-top: rem-calc(-20);
            padding-top: rem-calc(52);
            padding-bottom: rem-calc(32);
            justify-content: center;
            align-items: center;
          }

          &-container {
            @include mq('lg') {
              background-color: hsl(var(--white));
              box-shadow: $nav-elevation-desktop;
            }
          }

          &-wrapper {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 100%;
            background-color: hsl(var(--white));

            @include mq($max-width: 'md') {
              border-radius: rem-calc(15) rem-calc(15) 0 0;
              box-shadow: map-get($elevations, '04_dp');
              padding: rem-calc(32) 0 rem-calc(96);
              height: 0;
              bottom: -100%;
              opacity: 0;
            }

            @include mq('lg') {
              top: 100%;
              visibility: hidden;
              transition: visibility 0.8s;
            }
          }

          &-item {
            color: hsl(var(--grey-midnight));
            margin: 0 rem-calc(40);
            padding: rem-calc(16) 0;

            @include mq('sm') {
              margin: 0 rem-calc(125);
            }

            @include mq('lg') {
              padding: 0;
              margin: 0 spacing('3');

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .nav-item {
            &__link {
              align-items: center;
              display: flex;
              font-size: 1.25rem;
              font-weight: 500;
              letter-spacing: 0.0225rem;
              line-height: 1.2;
              @include mq('lg') {
                font-size: rem-calc(18);
                font-weight: $typography-weight-medium;
                letter-spacing: rem-calc(-0.26);
                line-height: calc(24 / 18);
              }
              &:hover,
              &.active {
                color: hsl(var(--red));
              }
            }
          }

          svg {
            font-size: 1rem;
            @include mq('lg') {
              display: none;
            }
          }
        }
        &__close {
          position: absolute;
          left: 50%;
          top: rem-calc(-28);
          transform: translateX(-50%);
          display: flex;
          padding: rem-calc(16);
          background: hsl(var(--white));
          border-radius: 50%;
          box-shadow: 0 -4px 4px -2px hsl(var(--black) / 0.14), 0 -1px 0 -1px hsl(var(--black) / 0.12);
          color: hsl(var(--red));
          font-size: rem-calc(16);
          cursor: pointer;

          @include mq('lg') {
            display: none;
          }
        }
      }

      &.has-submenu:hover .sub-nav__list-wrapper,
      &.has-submenu.active .sub-nav__list-wrapper {
        @include mq('lg') {
          visibility: visible;
        }
      }

      &.open {
        &.has-submenu {
          .sub-nav__list-wrapper {
            @include mq($max-width: 'md') {
              height: 65vh;
              opacity: 1;
              bottom: 0;
              transition: opacity 0.2s ease-in-out, bottom 0.5s ease-in-out, height 0.2s ease-in-out;
            }
          }
        }

        @include mq($max-width: 'md') {
          background-color: hsl(var(--grey-midnight) / 0.2);
          border-radius: rem-calc(10);
        }
      }
    }
  }

  .nav-item {
    &__btn {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: nowrap;
      font-size: rem-calc(13);
      font-weight: $typography-weight-medium;
      letter-spacing: rem-calc(-0.18);
      line-height: calc(16 / 13);
      padding: rem-calc(8);

      @include mq('lg') {
        font-size: rem-calc(18);
        font-weight: $typography-weight-medium;
        letter-spacing: rem-calc(-0.17);
        line-height: calc(15 / 18);
        padding: rem-calc(18) rem-calc(16);
        margin: rem-calc(8) rem-calc(6);

        &:hover {
          background-color: hsl(var(--grey-midnight) / 0.2);
          border-radius: rem-calc(10);
        }
      }

      @include mq('xl') {
        margin-inline: rem-calc(10);
      }

      &::after {
        display: none;
        content: '';
        position: absolute;
        bottom: 0;
        left: rem-calc(2);
        right: rem-calc(2);
        height: rem-calc(4);
        background-color: hsl(var(--white));
        border-radius: rem-calc(4);

        @include mq('lg') {
          bottom: rem-calc(8);
          left: rem-calc(6);
          right: rem-calc(6);
        }
      }

      &.trail,
      &.active {
        @include mq($max-width: 'md') {
          color: hsl(var(--red));
        }
        @include mq('lg') {
          &::after {
            display: block;
          }
        }
      }
    }

    &--small {
      @include mq($max-width: 'md') {
        margin: rem-calc(-7) 0;
      }

      .nav-item__btn {
        font-size: rem-calc(11);
        line-height: calc(10 / 11);
        letter-spacing: rem-calc(-0.21);
        padding: rem-calc(5);
        margin: rem-calc(3);

        @include mq('lg') {
          font-size: rem-calc(15);
          padding: rem-calc(12) rem-calc(8);
          margin: rem-calc(16) rem-calc(6) rem-calc(10);
        }

        &::after {
          @include mq($max-width: 'md') {
            height: rem-calc(2);
            bottom: rem-calc(2);
          }

          height: rem-calc(3);
          bottom: rem-calc(6);
        }

        &.trail,
        &.active {
          @include mq($max-width: 'md') {
            color: hsl(var(--white));
          }

          &::after {
            display: block;
          }
        }
      }

      .site-header__icon {
        @include mq($max-width: 'md') {
          padding: rem-calc(4) rem-calc(8);
        }

        padding-bottom: rem-calc(6);
      }

      .nav-item__label {
        padding-bottom: rem-calc(2);
      }
    }

    &--market {
      .site-header__icon--market {
        display: none;
      }

      .nav-item__label--market {
        display: none;
      }

      &.nav-item--market-selected {
        .site-header__icon {
          display: none;

          &--market {
            display: block;
          }
        }

        .nav-item__label {
          display: none;

          &--market {
            display: block;
          }
        }
      }
    }
  }

  &--nav-open {
    z-index: z('site-header-nav-open');
  }

  &__icon {
    box-sizing: content-box;
    font-size: rem-calc(24);
    height: 1em;
    padding: rem-calc(8);
    width: 1em;

    @include mq('lg') {
      font-size: rem-calc(32);

      .site-header__nav &,
      .site-header__menu & {
        padding-top: 0;
      }

      .site-header__nav & {
        padding-bottom: rem-calc(5);
      }
    }

    .nav-item--small & {
      font-size: rem-calc(20);

      @include mq('lg') {
        font-size: rem-calc(27);
      }
    }
  }
}
