.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}
