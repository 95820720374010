@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/elevation/elevation_variables';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/grid/_grid_mixins';

.input-label {
  color: hsl(var(--grey-midnight));
  display: block;
  font-size: rem-calc(11);
}

.input-message {
  font-size: rem-calc(11);

  &--error {
    color: hsl(var(--red));
  }
}

.market-finder {
  --btn-size: #{rem-calc(28)};
  --btn-offset-x: #{rem-calc(8)};
  --btn-offset-y: #{rem-calc(10)};

  @include mq('lg') {
    --btn-size: #{rem-calc(48)};
    --btn-offset-y: #{rem-calc(6)};
    --input-text-offset-y: #{rem-calc(30)}
  }
}

.compound-input {
  font-size: rem-calc(15);
  position: relative;
  background: hsl(var(--white));
  border: rem-calc(1) solid hsl(var(--grey-midnight));
  border-radius: rem-calc(24);

  @include mq('lg') {
    border-radius: rem-calc(30);
    font-size: rem-calc(20);
  }

  &.dropdown-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: rem-calc(12);
      margin-top: rem-calc(-12);
      background-color: hsl(var(--white));
      z-index: 1;
    }
  }

  .s-not-empty &,
  .s-focused & {
    box-shadow: map-get($elevations, '08_dp');
  }

  .s-has-error & {
    border-color: hsl(var(--red));
  }

  &__input {
    all: unset;
    box-sizing: border-box;
    font-size: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis;
    width: 100%;
    height: calc(var(--btn-size) + var(--btn-offset-y) * 2);
    padding-inline: calc(var(--btn-size) + var(--btn-offset-x) * 2);

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &::placeholder {
      color: hsl(var(--grey-stone));
    }

    @include mq('lg') {
      height: rem-calc(60);
      padding-inline: var(--input-text-offset-y) calc(var(--btn-size) * 2 + var(--btn-offset-x) * 3);
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: -1px;
    right: -1px;
    margin: 0;
    padding: 0;
    list-style: none;
    background: hsl(var(--white));
    border-bottom-left-radius: rem-calc(24);
    border-bottom-right-radius: rem-calc(24);
    border: rem-calc(1) solid hsl(var(--grey-midnight));
    border-top: 0;
    z-index: 1;

    .s-not-empty &,
    .s-focused & {
      box-shadow: map-get($elevations, '08_dp');
    }

    .s-has-error & {
      border-color: hsl(var(--red));
    }

    &-item {
      margin: 0;
      padding: rem-calc(8) calc(var(--btn-size) + var(--btn-offset-x));
      font-size: rem-calc(11);
      cursor: pointer;

      @include mq('lg') {
        padding: rem-calc(8) rem-calc(30);
        font-size: rem-calc(16);
      }

      &:last-child {
        margin-bottom: rem-calc(8);

        &:hover,
        &.selected {
          border-bottom-left-radius: rem-calc(24);
          border-bottom-right-radius: rem-calc(24);
        }
      }

      &:hover,
      &:focus-within,
      &.selected {
        background-color: hsl(var(--grey-wild-sand));
      }

      & > * {
        pointer-events: none;
      }

      span {
        font-size: rem-calc(20);
        color: hsl(var(--grey-stone));
        b {
          font-weight: $typography-weight-bold;
        }
      }
    }
  }

  &__button {
    position: absolute;
    top: var(--btn-offset-y);
    height: var(--btn-size);
    width: var(--btn-size);
    display: flex;
    justify-content: center;
    align-items: center;
    color: hsl(var(--grey-midnight));
    border-radius: 50%;
    z-index: 3;

    @include mq('lg') {
      cursor: pointer;
    }

    .icon {
      height: 1em;
      width: 1em;

      @include mq('lg') {
        height: 1.5em;
        width: 1.5em;
      }
    }

    &--search {
      left: var(--btn-offset-x);

      @include mq('lg') {
        display: none;
        left: auto;
        right: var(--btn-offset-x);
        color: hsl(var(--white));
        background: hsl(var(--grey-midnight));

        .s-not-empty &,
        .s-focused & {
          display: flex;
        }

        .not-has-search & {
          display: none;
        }
      }

      @include mq($max-width: 'md') {
        pointer-events: none;
      }
    }

    &--clear {
      display: none;
      right: var(--btn-offset-x);
      border: rem-calc(1) solid hsl(var(--grey-midnight));

      @include mq('lg') {
        right: calc(var(--btn-size) + var(--btn-offset-x) + rem-calc(12));

        .not-has-search & {
          right: calc(var(--btn-offset-x));
        }
      }

      .s-not-empty & {
        display: flex;
      }
    }

    &--location {
      right: var(--btn-offset-x);
      color: hsl(var(--white));
      background: hsl(var(--grey-midnight));

      .s-not-empty & {
        display: none;
      }

      @include mq('lg') {
        display: flex;

        .s-not-empty &,
        .s-focused & {
          display: none;
        }
      }
    }
  }
}
