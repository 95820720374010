@import '../../subatoms/vertical-spacing/vertical-spacing_functions';

.contentPage {
  transition: opacity 350ms;
}

.s-spinner__container {
  backdrop-filter: blur(5px);
  background-color: white;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 300ms, visibility 300ms;
  visibility: hidden;
  width: 100vw;

  .spinner-on & {
    opacity: 1;
    visibility: visible;
  }
}

.page-spinner {
  left: calc(50% - 100px);
  opacity: 0;
  position: absolute;
  top: calc(50% - 100px);
  transform: rotate(-100deg) scale(0.4);
  transition: transform 250ms 800ms, opacity 250ms 800ms;

  .spinner-on & {
    opacity: 1;
    z-index: 1;
    transform: none;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0;
  padding: 0.25rem;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(-500deg) scale(0.4);
  transition: 350ms;
  width: 100%;

  &--top {
    height: auto;
    margin-top: spacing('4');
  }

  .icon {
    font-size: rem-calc(80);
  }

  .spinner-active & {
    z-index: 1;
    opacity: 1;
    transform: none;
  }
}
