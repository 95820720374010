// Reboot anchors

// optionally append @content block to <a> selector
// Normalize has a rule for canvas, but it only affects IE 9-

// Bootstrap 4 (beta) Reboot & Normalize 7.0.0
// 1. Remove the gray background on active links in IE 10.
// 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
a {
	// sass-lint:disable no-vendor-prefixes property-sort-order
	background-color: transparent; // 1
	-webkit-text-decoration-skip: objects; // 2 - not sure if I personally like this reset
	// sass-lint:enable no-vendor-prefixes property-sort-order
}

// Bootstrap 4 (beta) Reboot
// And undo these styles for placeholder links/named anchors (without href)
// which have not been made explicitly keyboard-focusable (without tabindex).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402
a {
	&:not([href]) {
		&:not([tabindex]) {
			color: inherit;
			text-decoration: none;

			&:hover,
			&:focus {
				color: inherit;
				text-decoration: none;
			}

			&:focus {
				outline: 0;
			}
		}
	}
}
