@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'unified-spacing-variables';

@each $spacing-name, $spacing-config in $spacings {
  $spacing-mobile: map-get($spacing-config, 'mobile');
  $spacing-desktop: map-get($spacing-config, 'desktop');

  .spacing-above-#{$spacing-name} {
    padding-top: $spacing-mobile;

    @include mq('lg') {
      padding-top: $spacing-desktop;
    }
  }

  .spacing-below-#{$spacing-name} {
    padding-bottom: $spacing-mobile;

    @include mq('lg') {
      padding-bottom: $spacing-desktop;
    }
  }

  .spacing-above-#{$spacing-name}-sm {
    padding-top: $spacing-mobile;
  }

  .spacing-above-#{$spacing-name}-lg {
    @include mq('lg') {
      padding-top: $spacing-desktop;
    }
  }

  .spacing-below-#{$spacing-name}-sm {
    padding-bottom: $spacing-mobile;
  }

  .spacing-below-#{$spacing-name}-lg {
    @include mq('lg') {
      padding-bottom: $spacing-desktop;
    }
  }

  .spacing-p-above-#{$spacing-name}-sm {
    padding-top: $spacing-mobile;
  }

  .spacing-p-above-#{$spacing-name}-lg {
    @include mq('lg') {
      padding-top: $spacing-desktop;
    }
  }

  .spacing-p-below-#{$spacing-name}-sm {
    padding-bottom: $spacing-mobile;
  }

  .spacing-p-below-#{$spacing-name}-lg {
    @include mq('lg') {
      padding-bottom: $spacing-desktop;
    }
  }

  .spacing-m-above-#{$spacing-name}-sm {
    margin-top: $spacing-mobile;
  }

  .spacing-m-above-#{$spacing-name}-lg {
    @include mq('lg') {
      margin-top: $spacing-desktop;
    }
  }

  .spacing-m-below-#{$spacing-name}-sm {
    margin-bottom: $spacing-mobile;
  }

  .spacing-m-below-#{$spacing-name}-lg {
    @include mq('lg') {
      margin-bottom: $spacing-desktop;
    }
  }


}
