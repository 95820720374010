// Reboot interactions such as touch delays and focus on non-tabable nodes
// Bootstrap 4 (beta) Reboot
// Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.

// In particular, unlike most other browsers, IE11+Edge on Windows 10 on touch devices and IE Mobile 10-11
// DON'T remove the click delay when `<meta name="viewport" content="width=device-width">` is present.
// However, they DO support removing the click delay via `touch-action: manipulation`.
// See:
//  - https://getbootstrap.com/docs/4.0/content/reboot/#click-delay-optimization-for-touch
//  - https://caniuse.com/#feat=css-touch-action
//  - https://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay
a,
area,
button,
[role="button"],
// sass-lint:disable force-pseudo-nesting
input:not([type="range"]),
label,
select,
summary,
textarea {
	touch-action: manipulation;
}

// Bootstrap 4 (beta) Reboot
// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that might still respond to pointer events.
// Credit: https://github.com/suitcss/base
[tabindex="-1"]:focus {
	// sass-lint:disable no-important
	outline: none !important;
}
