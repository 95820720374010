@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';

$page-sector-overlap: rem-calc(20);

$spacing-mobile-none: 0;
$spacing-mobile-s: spacing('3');
$spacing-mobile-m: spacing('4');
$spacing-mobile-l: spacing('5');
$spacing-mobile-xl: spacing('10');
$spacing-mobile-xxl: spacing('10') + $page-sector-overlap;

$spacing-desktop-none: 0;
$spacing-desktop-s: spacing('3');
$spacing-desktop-m: spacing('7');
$spacing-desktop-l: spacing('10');
$spacing-desktop-xl: spacing('15');
$spacing-desktop-xxl: spacing('15') + $page-sector-overlap;

$spacings: (
  'none': (
    'mobile': $spacing-mobile-none,
    'desktop': $spacing-desktop-none,
  ),
  's': (
    'mobile': $spacing-mobile-s,
    'desktop': $spacing-desktop-s,
  ),
  'm': (
    'mobile': $spacing-mobile-m,
    'desktop': $spacing-desktop-m,
  ),
  'l': (
    'mobile': $spacing-mobile-l,
    'desktop': $spacing-desktop-l,
  ),
  'xl': (
    'mobile': $spacing-mobile-xl,
    'desktop': $spacing-desktop-xl,
  ),
  'xxl': (
    'mobile': $spacing-mobile-xxl,
    'desktop': $spacing-desktop-xxl,
  ),
);
