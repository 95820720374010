@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/math/rounding-functions';
@import 'scss-mixins-functions-variables/scss/layout/visually-hidden-mixins';
@import '../../../subatoms/grid/grid_mixins';
@import '../../../subatoms/color/color_functions';
@import '../../../subatoms/typography/typography_variables';
@import '../../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../../subatoms/elevation/elevation_variables';
@import '../../../atoms/buttons/button-states-mixin';

.tabs--offers {
  @include mq($max-width: 'md') {
    display: flex;
    flex-direction: column;
  }

  @include mq('lg') {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }

  .page-sector--intro {
    @include mq($max-width: 'md') {
      margin-top: 0;
      padding-top: 0;
    }

    @include mq('lg') {
      width: 100%;
    }
  }

  &:not(:has(
        .tab-input--offers:focus-visible,
        .tab-input--weeks:focus-visible,
        .tab-input--weeks-menu:focus-visible,
        .tab-input--period:focus-visible
        )) {
    .tabs__selection--group {
      overflow: hidden;
      box-shadow: none;
    }
  }

  .market-tile {
    &__container {
      @include mq($max-width: 'md') {
        order: -1;
        padding-block: rem-calc(36) rem-calc(24);
      }

      @include mq('lg') {
        padding-bottom: 0;
      }
    }
  }

  .tabs {
    &__selection-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: spacing('1');
      text-align: center;
      padding-inline: var(--offset);
      font: $typography-weight-medium #{rem-calc(15)} / calc(24 / 15) $typography-family-akko;

      @include mq('lg') {
        padding-left: 0;
        row-gap: spacing('3');
      }

      &--edit {
        .tabs__item {
          pointer-events: none;
        }
      }
    }

    &__selection {
      &--group {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: calc(var(--tabs-group-spacing) * 2);
        padding: var(--tabs-group-spacing);
        border-radius: var(--tabs-border-radius);
        background-color: hsl(var(--grey-cobble-stone));

        * {
          z-index: 2;
        }
      }

      &--weeks {
        margin-bottom: 0;
      }
    }

    &__item {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex: 50%;
      padding: rem-calc(10) spacing('2');
      border-radius: var(--tabs-border-radius);
      height: 100%;
      width: 50%;
      text-wrap: nowrap;
      background-position: center;
      color: hsl(var(--grey-midnight));

      @include mq($max-width: 'md') {
        width: 50%;
      }

      @include mq('lg') {
        padding-block: rem-calc(18);
      }

      &--message {
        font: $typography-weight-semi-bold #{rem-calc(15)} / calc(24 / 15) $typography-family-sans;
        text-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 100%;

        span {
          text-align: start;
        }
      }

      .icon {
        margin-right: rem-calc(6);
        min-width: 1.2em;
        width: 1.2em;
        height: 1.2em;
      }

      &-text {
        &-no-handout {
          display: none;
          margin-bottom: spacing('1');
          font-family: $typography-family-sans;
          font-weight: $typography-weight-semi-bold;
          line-height: decimal-round(calc(21 / 15), 1);
        }
      }

      &-handout-link {
        display: none;
        padding: 0;
      }
    }

    &__glider {
      position: absolute;
      z-index: 1;
      display: flex;
      height: calc(100% - var(--tabs-group-spacing) * 2);
      width: calc(50% - var(--tabs-group-spacing) * 2);
      border-radius: var(--tabs-border-radius);
      transition: 0.25s ease-out;
      background-color: hsl(var(--grey-midnight));
      box-shadow: map-get($elevations, '08_dp');
    }

    &__content {
      &-area {
        width: 100%;
      }

      &--weeks {
        position: relative;
      }
    }
  }

  input[id="offers-input"]:checked ~ .tabs__selection-area .tabs__glider--offers,
  input[id="current-week"]:checked ~ .tabs__selection-area .tabs__glider--weeks,
  input[id="current-week-menu"]:checked ~ .tabs__selection-area .tabs__glider--weeks-menu {
    translate: 0;
  }

  input[id="handout-input"]:checked ~ .tabs__selection-area .tabs__glider--offers,
  input[id="next-week"]:checked ~ .tabs__selection-area .tabs__glider--weeks,
  input[id="next-week-menu"]:checked ~ .tabs__selection-area .tabs__glider--weeks-menu {
    translate: calc(100% + var(--tabs-group-spacing) * 2);
  }

  [type='radio'] {
    @for $i from 1 through 2 {
      &:nth-child(#{$i} of .tab-input--offers):checked {
        ~ .tabs__selection-area {
          .tabs__item--offers:nth-of-type(#{$i}) {
            color: hsl(var(--white));
          }
        }

        &:focus-visible {
          ~ .tabs__selection-area .tabs__glider--offers {
            @include button-focus-blue-outline();
          }
        }
      }

      &:nth-child(#{$i} of .tab-input--offers):not(:checked) {
        ~ .tabs__selection-area .tabs__item--offers:nth-of-type(#{$i}) {
          @include button-active(var(--tabs-border-radius));

          &:hover {
            z-index: 1;
            background: hsl(from hsl(var(--grey-cobble-stone)) h s calc(l * 0.9));
            color: hsl(var(--grey-stone));
            cursor: pointer;
          }
        }

        // hide offers or handout for screen readers and keyboard navigation
        ~ .tabs__content-area .tabs__content--offers:nth-of-type(#{$i}) {
          visibility: hidden;
        }
      }
    }

    &:nth-child(2 of .tab-input--offers).initial-hash:target {
      ~ .tabs__selection-area .tabs__item--offers:nth-of-type(1) {
        background-color: transparent;
        box-shadow: none;
      }

      ~ .tabs__selection-area .tabs__item--offers:nth-of-type(2) {
        background-color: hsl(var(--white));
        box-shadow: map-get($elevations, '02_dp');
      }

      ~ .tabs__content-area .tabs__content--offers-handout:nth-of-type(2) {
        //show only the tab content that corresponds to the checked input
        @include visually-hidden-destroy();
        overflow: visible;
      }
    }


    &:nth-child(2 of .tab-input--offers):checked {
      @for $i from 1 through 2 {
        ~ .tabs__selection-area .tabs__item--weeks:nth-of-type(#{$i}) {
          //visually make week tabs inactive when handout tab is active
          box-shadow: none !important;
          pointer-events: none;
          background-color: inherit;
          color: inherit;
        }
      }

      ~ .tabs__selection-area .tabs__glider--weeks {
        display: none;
      }

      ~ .tabs__selection-area .tabs__item--weeks {
        color: hsl(var(--grey-midnight)) !important;
      }

      ~ .tab-input--weeks {
        //remove accessibility for week tabs when handout tab is active
        display: none !important;
      }
    }

    @for $i from 1 through 2 {
      &:nth-child(#{$i} of .tab-input--weeks):checked {
        ~ .tabs__selection-area .tabs__item--weeks:nth-of-type(#{$i}) {
          color: hsl(var(--white));
        }

        &:focus-visible {
          ~ .tabs__selection-area .tabs__glider--weeks {
            @include button-focus-blue-outline();
          }
        }
      }

      &:nth-child(#{$i} of .tab-input--weeks):not(:checked) {
        ~ .tabs__selection-area .tabs__item--weeks:nth-of-type(#{$i}) {
          @include button-active(var(--tabs-border-radius));

          &:hover {
            z-index: 1;
            background: hsl(from hsl(var(--grey-cobble-stone)) h s calc(l * 0.9));
            color: hsl(var(--grey-stone));
            cursor: pointer;
          }
        }

        // hide week offers for screen readers and keyboard navigation
        ~ .tabs__content-area .week-offers:nth-of-type(#{$i}) {
          visibility: hidden;
        }
      }
    }

    &:nth-child(2 of .tab-input--weeks).initial-hash:target {
      ~ .tabs__selection-area .tabs__item--weeks:nth-of-type(1) {
        background: transparent;
        box-shadow: none;
      }

      ~ .tabs__selection-area .tabs__item--weeks:nth-of-type(2) {
        background: hsl(var(--white));
        box-shadow: map-get($elevations, '02_dp');
      }

      ~ .tabs__content-area .tabs__content--weeks .tabs__content:nth-of-type(2) {
        //show only the tab content that corresponds to the checked input
        @include visually-hidden-destroy();
        overflow: visible;
      }
    }
  }
}
