@import '../../atoms/semantic/headlines/headlines_mixins';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';

@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';

.content-hdln {
  margin-top: 0;
  &--large {
    margin-bottom: spacing('4');
  }
}
