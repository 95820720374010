@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/math/rounding-functions';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import '../../../subatoms/typography/typography_variables';

@mixin h1() {
  font: $typography-weight-bold #{rem-calc(35)} / decimal-round(calc(39 / 35), 3) $typography-family-akko;
  text-transform: none;

  @include mq('lg') {
    font-size: rem-calc(65);
    line-height: decimal-round(calc(67 / 65), 3);
  }
}

@mixin h2() {
  font: $typography-weight-medium #{rem-calc(30)} / decimal-round(calc(37 / 30), 3) $typography-family-akko;
  letter-spacing: rem-calc(0.16);
  text-transform: none;

  @include mq('lg') {
    font-size: rem-calc(40);
    letter-spacing: rem-calc(0.21);
    line-height: decimal-round(calc(43 / 40), 3);
  }
}

@mixin h3() {
  @include h2();
  font-size: rem-calc(30);
  line-height: decimal-round(calc(33 / 30), 3);
  margin-top: 0;
  text-transform: none;

  @include mq('lg') {
    line-height: decimal-round(calc(39 / 30), 3);
  }
}

@mixin h4() {
  font: $typography-weight-bold #{rem-calc(15)} / decimal-round(calc(20 / 15), 3) $typography-family-akko;
  letter-spacing: rem-calc(0.08);
  text-transform: uppercase;

  @include mq('lg') {
    font-size: rem-calc(20);
    letter-spacing: rem-calc(0.11);
    line-height: decimal-round(calc(28 / 24), 3);
  }
}

@mixin h5() {
  font: $typography-weight-bold #{rem-calc(12)} / 1.4 $typography-family-sans;
  letter-spacing: 0.093ch;
  text-transform: none;

  @include mq('lg') {
    font-size: rem-calc(16);
  }
}

@mixin h6() {
  @include h5();
  font-size: rem-calc(11);
  text-transform: none;

  @include mq('lg') {
    font-size: rem-calc(13);
  }
}

@mixin h7() {
  font: $typography-weight-bold #{rem-calc(24)} / decimal-round(calc(28 / 24), 3) $typography-family-akko;
  letter-spacing: normal;

  @include mq('lg') {
    font-size: rem-calc(30);
    line-height: decimal-round(calc(34 / 30), 3);
  }
}
