@import 'scss-mixins-functions-variables/scss/reset/list/reset-list-mixins';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../atoms/semantic/headlines/headlines_mixins';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/elevation/elevation_variables';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/grid/_grid_mixins';

:root {
  --market-services-gap: #{spacing('2')};
  --market-services-offset: #{rem-calc(60)};

  @include mq('lg') {
    --market-services-gap: #{spacing('3')};
  }
}

.market-tile {
  color: hsl(var(--grey-stone));
  transition: background 200ms;

  &__card {
    background-color: hsl(var(--white));
    border-radius: rem-calc(15);
    padding: spacing('2');
    overflow: hidden;

    &:is([href]) {
      display: block;
      text-decoration: none;

      @include mq('lg') {
        transition: box-shadow 0.2s linear;

        &:hover {
          box-shadow: map-get($elevations, '16_dp');
        }
      }
    }

    @include mq('lg') {
      padding: spacing('3');

      &.s-active {
        background-color: hsl(var(--grey-wild-sand));
      }
    }

    &--contained {
      padding: 0;
    }

    &--elevated {
      box-shadow: map_get($elevations, 'teaser');
    }

    &--link {
      @include mq('lg') {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__section {
    &:not(:first-child) {
      margin-top: spacing('3');

      @include mq('lg') {
        margin-top: spacing('4');
      }
    }
  }

  &__container {
    width: 100%;
    margin-inline: auto;
    padding-inline: var(--offset);

    @include mq('lg') {
      width: 50%;
      max-width: var(--tabs-section-width);
      margin-inline: unset;
      padding-inline: var(--offset) spacing('5');
    }

    &--edit {
      .btn {
        pointer-events: none;
      }
    }

    .market-tile {
      &--switch {
        height: rem-calc(87);
        border-radius: rem-calc(10);
        padding: rem-calc(10);

        @include mq('lg') {
          padding: spacing('2');
        }

        @include mq('lg') {
          height: rem-calc(150);
        }

        .market-tile {
          &__hdln {
            margin: 0;
            color: hsl(var(--grey-stone));

            @include mq($max-width: 'md') {
              display: none;
            }
          }

          &__subline {
            margin: 0;
          }

          &__status {
            &:not([hidden]) {
              margin-top: 0;

              + [data-status-closed-between] {
                display: none;
              }
            }

            &:is([hidden]) {
              + [data-status-closed-between] .market-tile__status-text span {
                display: none;
              }
            }

            &-text {
              color: hsl(var(--grey-midnight));
              span {
                display: none;
              }
            }
          }
        }
      }

      &__card {
        &:has(.market-tile__skeleton:not(.hidden)) {
          display: flex;
          align-items: center;
        }
      }

      &__skeleton {
        display: flex;
        flex-direction: column;
        gap: rem-calc(10);

        @include mq('lg') {
          gap: spacing('1.5');
        }

        &:not(.hidden) {
          flex-basis: 75%;
          margin-inline: auto;
        }

        .skeleton {
          &--text {
            max-height: rem-calc(14);
            margin-bottom: 0;
          }
        }
      }

      &__select,
      &__current {
        display: grid;
        justify-items: center;
        width: 100%;
      }

      &__select {
        gap: spacing('1');
      }

      &__current {
        height: 100%;

        @include mq($max-width: 'md') {
          grid-template-columns: 1fr 1fr;
          place-items: center;
        }

        @include mq('lg') {
          align-content: center;
          gap: spacing('3');
        }
      }

      &__btn-not-selected.btn {
        width: auto;
        margin: 0;

        @include mq($max-width: 'md') {
          padding-block: 0;
          height: rem-calc(32);
        }

        .icon {
          @include mq($max-width: 'md') {
            font-size: rem-calc(18);
            vertical-align: middle;
            margin-top: rem-calc(-3);
          }
        }
      }

      &__btn-selected.btn {
        width: auto;
        padding: rem-calc(3) rem-calc(15);

        @include mq('lg') {
          align-self: end;
        }

        .icon {
          font-size: rem-calc(20);
          vertical-align: middle;
          margin: rem-calc(-2) rem-calc(4) 0 0;
        }
      }
    }
  }

  &__category:not([hidden]) {
    display: flex;
    justify-content: center;
  }

  /* Resize market tile if no market service is present for selected market and market info */
  &.no-market-bringoo.no-market-hall.no-market-scan-go {
    .market-tile {
      &__services-wrapper {
        display: none;
      }

      &__services__button {
        display: none;
      }

      &--half {
        @include mq('lg') {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &__expand-info {
        @include mq('lg') {
          max-width: fit-content;
          margin: 0 auto;

          > .l-col {
            flex-basis: 50%;
            max-width: 50%;
          }
          .market-tile__more-info {
            > .l-col {
              flex-basis: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    .market-finder__services {
      display: none;
    }
  }

  .btn {
    max-width: 19rem;
    min-width: unset;
    padding-left: spacing('2');
    padding-right: spacing('2');
    width: 100%;
  }

  &__image {
    aspect-ratio: 3 / 2;
    object-fit: cover;
  }

  &__head {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    color: hsl(var(--grey-midnight));
    margin-bottom: spacing('2');

    &-subline {
      margin-bottom: 0;
    }

    .h4 {
      margin: 0;
    }

    @include mq($max-width: 'md') {
      .market-tile__route {
        margin: 0;

        .btn {
          padding: 0;
          line-height: 1;
          max-width: none;
        }
      }
    }
  }

  &__main {
    padding: spacing('2');
  }

  &__foot {
    margin-top: spacing('2');
  }

  &__hdln {
    color: hsl(var(--grey-midnight));
    font-weight: $typography-weight-bold;

    @include mq('lg') {
      max-width: 70%;
    }

    &:last-child {
      max-width: 100%;
      width: 100%;
      text-align: center;
    }

    &--secondary {
      text-transform: capitalize;
      font-family: $typography-family-sans;
      letter-spacing: rem-calc(0.16);
    }

    &--select {
      letter-spacing: rem-calc(0.16);
    }
  }

  &__title {
    margin-bottom: spacing('1');
    color: hsl(var(--grey-midnight));
    font-weight: $typography-weight-bold;
  }

  &__subline {
    text-align: center;
  }

  &__address-distance {
    font-size: rem-calc(15);
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin-bottom: spacing('1');

    @include mq('lg') {
      font-size: rem-calc(20);
    }
  }

  &__distance {
    color: hsl(var(--grey-midnight));
    font-weight: $typography-weight-bold;
    text-align: right;
    white-space: nowrap;
  }

  &__address {
    font-family: inherit;
    display: flex;
    flex-direction: column;
    margin: 0;
    white-space: pre-wrap;
  }

  &__status {
    &:not([hidden]) {
      display: flex;
      align-items: flex-start;
      margin-top: spacing('0.5');
      @include mq('lg') {
        margin-top: spacing('1');
      }
    }

    &.skeleton--text {
      margin-bottom: 0;
    }

    &-icon {
      min-width: 1em;
      margin-right: rem-calc(6);
      margin-top: rem-calc(3);

      @include mq('lg') {
        margin-top: spacing('0.5');
      }
    }

    &-text--large {
      font-size: rem-calc(15);

      @include mq('lg') {
        font-size: rem-calc(20);
      }
    }

    + .market-tile__non-food--show {
      margin-top: spacing('0.5');
    }
  }

  &__non-food {
    display: none;
    color: hsl(var(--grey-midnight));
    margin-top: spacing('1');
    flex-direction: row;
    align-items: flex-start;

    &--show {
      display: flex;
    }

    &--margin-top {
      @include mq('lg') {
        margin-top: rem-calc(20);
      }
    }

    .icon {
      font-size: rem-calc(11);
      margin: rem-calc(5) rem-calc(5) 0 0;
      width: rem-calc(14);

      @include mq('lg') {
        font-size: rem-calc(15);
        margin-top: rem-calc(7);
        width: rem-calc(20);
      }
    }
  }

  &__links {
    display: flex;
    gap: spacing('2');
    flex-wrap: wrap;

    .btn {
      display: flex;
      align-items: center;
      gap: rem-calc(4);
      width: auto;
      padding-top: spacing('1');
      padding-bottom: spacing('1');
      line-height: 0;

      &--market-finder {
        filter: blur(0);
        transition: filter 0.2s ease-in;

        &.animate {
          filter: blur(rem-calc(2));
        }
      }
    }
  }

  &__services {
    display: flex;
    flex-wrap: wrap;
    row-gap: var(--market-services-gap);

    .market-services {
      flex-basis: 100%;
    }

    &__headline {
      margin: spacing('1') 0 spacing('2');

      @include mq('lg') {
        margin: 0 0 spacing('2.5');
      }
    }
  }

  &__phone {
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    margin-block: spacing('1') 0;
  }

  .collapsible__control {
    margin-top: spacing('5');

    .icon {
      $collapsible-control-icon-line-width: rem-calc(2);
      position: relative;
      margin-right: rem-calc(4);
      vertical-align: middle;

      &:before,
      &:after {
        background-color: currentColor;
        content: '';
        left: 50%;
        position: absolute;
        top: 50%;
      }

      &:not(.icon--no-anim) {
        &:before,
        &:after {
          transform: rotate(-90deg);
          transition: all 0.5s ease-in-out;
        }
      }

      &:before {
        width: rem-calc(11);
        height: $collapsible-control-icon-line-width;
        margin-left: rem-calc(-5);
        margin-top: calc(#{$collapsible-control-icon-line-width} / -2);
        opacity: 1;
      }

      &:after {
        height: rem-calc(11);
        margin-left: calc(#{$collapsible-control-icon-line-width} / -2);
        margin-top: rem-calc(-5);
        width: $collapsible-control-icon-line-width;
      }
    }

    &[aria-expanded='true'] {
      svg:not(.icon--no-anim) {
        transform: rotate(180deg);
      }

      .collapsible__control-closed {
        display: none;
      }

      .icon {
        opacity: 1;

        &:not(.icon--no-anim) {
          &:before,
          &:after {
            transform: rotate(90deg);
            transition: all 0.5s ease-in-out;
          }
        }

        &:before {
          opacity: 0;
        }
      }
    }

    &:not([aria-expanded='true']) {
      margin-bottom: 0;
      .collapsible__control-open {
        display: none;
      }
    }
  }

  &__opening-hours {
    td {
      &:not(:last-child) {
        padding-right: 0.5em;
      }
    }
  }

  &__list {
    @include reset-list();
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: rem-calc(15);
    column-gap: rem-calc(8);
    @include mq('lg') {
      margin-top: 0;
      column-gap: rem-calc(16);
    }

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0;
      flex-basis: 100%;
    }

    .icon {
      height: auto;
      width: 100%;
      max-width: rem-calc(75);
      max-height: rem-calc(50);
    }

    span {
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      margin-top: rem-calc(10);
      white-space: nowrap;
      color: hsl(var(--grey-midnight));
    }

    &--pay-options {
      margin-bottom: spacing('2.5');
      padding-bottom: spacing('5');
      column-gap: 0;
      li {
        height: rem-calc(50);
        flex-basis: auto;

        &:nth-child(1) {
          .icon {
            width: rem-calc(36);
            height: rem-calc(35);
          }
        }
        &:nth-child(2) {
          .icon {
            width: rem-calc(56);
            height: rem-calc(35);
          }
        }
        &:nth-child(3) {
          .icon {
            width: rem-calc(47);
            height: rem-calc(48);
          }
        }
      }
    }
  }

  &__route {
    margin-bottom: spacing('2');
    margin-top: spacing('2');
    @include mq('md') {
      margin-top: 0;
    }

    .btn {
      @include mq('lg') {
        width: auto;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        text-align: left;
      }

      .icon {
        font-size: rem-calc(16);
        margin-right: spacing('0.25');
      }
    }
  }

  &__select {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: spacing('2');
      margin-top: spacing('5');

      @include mq('md') {
        margin-top: 0;
      }

      @include mq('lg') {
        margin-top: spacing('5');
      }

      .btn {
        margin: 0;
      }
    }

    .collapsible__content[aria-hidden='true'] + & {
      @include mq('md') {
        margin-top: -3em;
      }

      @include mq('lg') {
        margin-top: -4em;
      }
    }
  }

  &__link {
    color: inherit;
    font-size: rem-calc(15);

    &--underline {
      padding-left: spacing('5');
      text-decoration: underline;
      line-height: 1;
      @include mq('lg') {
        padding-left: spacing('6');
      }
    }
  }

  &__services__button {
    position: absolute;
    left: 0;
    bottom: rem-calc(16);
    width: 100%;
    text-align: center;

    .btn {
      width: auto;
      margin-top: 0;
      padding-top: rem-calc(3);
      padding-bottom: rem-calc(3);
    }
  }

  &--radio {
    border: 1px solid transparent;
    color: hsl(var(--grey-midnight));
    display: block;
    font-size: rem-calc(13);
    padding: spacing('6') 0;
    position: relative;

    .l-row {
      align-items: center;
    }

    [class^='l-col'],
    [class*=' l-col'] {
      padding-bottom: 0;
    }

    .market-tile {
      &__hdln {
        text-align: left;
      }

      &__distance {
        position: absolute;
        right: spacing('3');
        top: spacing('2');

        @include mq('md') {
          font-size: rem-calc(20);
        }
      }

      &__radio-indicator {
        align-items: center;
        border: 1px solid #a0a5ad;
        border-radius: 50%;
        color: hsl(var(--grey-midnight));
        display: flex;
        font-size: rem-calc(32);
        height: 1em;
        justify-content: center;
        margin: auto;
        width: 1em;

        > .icon {
          opacity: 0;
        }
      }
    }
  }

  &__radio {
    &:checked {
      + .market-tile--radio .market-tile__radio-indicator {
        > .icon {
          opacity: 1;
        }
      }
    }

    &:focus {
      + .market-tile--radio {
        border-color: rgb(255, 210, 0);
      }
    }
  }

  &__more-info {
    margin-top: spacing('2');

    @include mq('lg') {
      margin-top: spacing('3');
    }
  }
}
