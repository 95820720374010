@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../atoms/semantic/headlines/headlines_mixins';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/elevation/elevation_variables';
@import '../../subatoms/grid/grid_variables';
@import '../../subatoms/typography/typography_variables';
@import '../../atoms/unified-spacing/unified-spacing-mixin';
@import './page-sector-mixins';

$page-sector-overlap: rem-calc(20);
$button-width: rem-calc(229);
$button-height: rem-calc(42);
$button-typography-size: rem-calc(15);
$button-typography-weight: $typography-weight-medium;
$button-typography-line-height: calc(24 / 15);
$button-margin-top: spacing('5');
$button-margin-top-desktop: spacing('10');

$header-offset-mobile: spacing('6');
$header-offset-desktop: spacing('11');

.page-sector {
  &--intro {
    .content-btns {
      & + .content-btns {
        margin-top: 0;
      }
    }
  }

  & > header {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &__header {
    padding-bottom: $spacing-mobile-m;

    @include mq('lg') {
      padding-bottom: $spacing-desktop-m;
    }

    .page-sector--spacing-below-none & {
      padding-bottom: 0;
    }
  }

  // page-sector__head is a mandatory part of the component
  &__head {
    font-weight: $typography-weight-medium;
    display: flex;
    flex: 1;

    @include mq($max-width: 'lg') {
      font-size: rem-calc(16);
    }

    & > * {
      flex: 1;
    }

    &--offset-width {
      .l-col {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: $spacing-mobile-m;

      @include mq('lg') {
        margin-bottom: $spacing-desktop-m;
      }
    }
  }

  &__hdln {
    margin-bottom: $spacing-mobile-s;
    margin-top: 0;

    @include mq('lg') {
      margin-bottom: $spacing-desktop-s;
    }
  }

  &__sbln {
    color: hsl(var(--grey-stone));
    font: $typography-weight-semi-bold #{rem-calc(15)} / decimal-round(calc(21 / 15), 2) $typography-family-sans;
    @include mq('lg') {
      font-size: rem-calc(20);
    }
  }

  &__head-media {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;

    & + .page-sector__head {
      margin-top: $spacing-mobile-l;
      padding-top: 0;

      @include mq('lg') {
        margin-top: $spacing-desktop-l;
      }
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 1920px;

      &.offers-ratio {
        aspect-ratio: 21/9;
        object-fit: cover;

        @include mq('lg') {
          aspect-ratio: 55/9;
        }
      }
    }
  }

  &__show-all {
    display: block;
    font-family: $typography-family-akko;
    height: $button-height;
    margin: $spacing-mobile-m auto 0;
    text-align: center;
    text-decoration: none;
    width: $button-width;

    @include mq('lg') {
      margin: $spacing-desktop-m auto 0;
    }
  }

  &__info-message--author {
    display: block;
    font-size: $button-typography-size;
    font-weight: $button-typography-weight;
    line-height: $button-typography-line-height;
    margin-top: $button-margin-top;
    text-align: center;
  }

  &--above-prev,
  &--above-next {
    position: relative;

    &::before,
    &::after {
      border-radius: inherit;
      content: '';
      display: block;
      height: 60px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &--above-prev {
    border-top-left-radius: $page-sector-overlap;
    border-top-right-radius: $page-sector-overlap;
    margin-top: $page-sector-overlap * -1;

    &::before {
      box-shadow: 0 -19px 30px -15px rgba(0, 0, 0, 0.14), 0 -10px 18px -9px rgba(0, 0, 0, 0.12);
      top: 0;
    }
  }

  &--under-next {
    padding-bottom: $spacing-desktop-xxl;

    @include mq('lg') {
      padding-bottom: $spacing-desktop-xxl;
    }
  }

  &--above-next {
    border-bottom-left-radius: $page-sector-overlap;
    border-bottom-right-radius: $page-sector-overlap;

    &::after {
      bottom: 0;
      box-shadow: 0 19px 30px -15px rgba(0, 0, 0, 0.14), 0 10px 18px -9px rgba(0, 0, 0, 0.12);
    }
  }

  &--overlap-next {
    margin-bottom: $page-sector-overlap * -1;
  }

  &--break-overlap {
    margin-top: $page-sector-overlap;
  }

  &--rounded {
    border-radius: rem-calc(10);
  }

  &--offers {
    display: flex;
    flex-direction: column;
  }
}

@include page-sector-spacing();
