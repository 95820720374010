@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/reset/list/reset-list-mixins';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/grid/grid_variables';

/* styles that target only product detail as a separate page */
main {
  .detail-block {
    --bubble-left: 0;
    --badge-left-spacing: #{rem-calc(8)};

    //badge positioning only on pdp
    --badge-top: #{spacing('6')};

    @include mq('lg') {
      --badge-left-spacing: #{rem-calc(16)};
      --badge-top: #{spacing('13')};
    }

    &__bubble {
      @include mq($max-width: 'sm') {
        padding: 0;
        margin-left: #{rem-calc(-24)};
      }

      @include mq('md') {
        position: absolute;
        width: auto;
      }
    }

    .bubble {
      --bubble-margin-top: 0;
      --bubble-margin-left: 0;
      --bubble-offset-left: 0;
      --bubble-offset-right: 0;
      --bubble-h-text-alignment: center;
      --bubble-v-text-alignment: center;

      &__wrap {
        overflow: unset;

        @include mq('lg') {
          border-top-left-radius: 0;
        }
      }

      .ellipsis {
        text-align: center;
      }
    }

    &__media-container {
      &:first-child {
        margin-top: 0;
      }

      &:not(:first-child) {
        margin-top: #{rem-calc(-24)};

        @include mq('md') {
          margin-top: #{rem-calc(128)};
        }

        @include mq('lg') {
          margin-top: #{rem-calc(192)};
        }
      }
    }
  }
}

/* styles that target modal and separate pages */
.detail-block {
  --badge-top: #{spacing('2.5')};
  @include mq('lg') {
    --badge-top: #{rem-calc(60)};
  }

  position: relative;
  padding-top: spacing('2');

  &__bubble {
    display: flex;
    column-gap: spacing('2');
    align-items: flex-start;

    .badge {
      margin-top: var(--badge-top);
    }
  }

  &__carousel {
    @include reset-list;
    align-items: flex-end;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: spacing('3');
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;

    // Edge
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    // Firefox
    scrollbar-width: none;

    // chrome
    &::-webkit-scrollbar {
      width: 0;
    }

    @include mq('lg') {
      max-width: none;
    }

    > li {
      flex: 0 0 100%;
      scroll-snap-align: start;
    }

    + * {
      margin-top: spacing('3');
    }
  }

  &__carousel-slide {
    aspect-ratio: 256 / 206;

    &--wine {
      aspect-ratio: 1;
    }

    &.is-video {
      padding-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      iframe,
      video {
        height: 100%;
        width: 100%;
      }

      .video__poster {
        object-fit: cover;
      }

      .video__container {
        &--landscape {
          width: 100%;
        }

        &--portrait {
          height: 100%;
        }
      }
    }

    img {
      object-fit: contain;
      aspect-ratio: inherit;
    }
  }

  &__media {
    text-align: center;
    margin-bottom: spacing('2');

    @include mq('lg') {
      margin-left: auto;
      margin-right: auto;
      margin-top: spacing('3');
      max-width: 75%;
      position: relative;
    }

    &-container {
      &:first-child {
        margin-top: #{rem-calc(128)};

        @include mq('lg') {
          margin-top: #{rem-calc(200)};
        }
      }
    }
  }

  &__thumbs {
    @include reset-list;
    align-items: center;
    display: inline-flex;
    height: rem-calc(84);
    justify-content: center;
    margin: spacing('3') auto 0;
    width: 100%;

    @include mq('lg') {
      height: rem-calc(100);
    }
  }

  &__thumbs-slide {
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    justify-content: center;
    margin: rem-calc(8) rem-calc(10);
    max-width: 20%;
    width: rem-calc(64);

    @include mq('lg') {
      width: rem-calc(80);
    }

    &--video:only-child {
      display: none;
    }

    &--wine {
      height: rem-calc(52);

      @include mq('lg') {
        height: rem-calc(64);
      }

      a {
        height: 72%;
        max-height: 72%;

        img {
          height: 100%;
          width: auto;
        }
      }

      &.s-active {
        a {
          transform: scale(1.5);
        }
      }
    }

    a {
      display: block;
      max-width: 75%;
      width: rem-calc(48);

      @include mq('lg') {
        max-width: 72%;
        width: rem-calc(57);
      }

      &.has-video-thumb {
        text-decoration: none;

        svg {
          font-size: rem-calc(36);
          @include mq('lg') {
            font-size: rem-calc(42);
          }
        }

        span {
          display: block;
          font-size: rem-calc(12);
          line-height: 1;
          @include mq('lg') {
            font-size: rem-calc(14);
          }
        }
      }
    }

    &.s-active {
      a {
        transform: scale(1.5);

        &.has-video-thumb {
          svg {
            font-size: rem-calc(48);
            @include mq('lg') {
              font-size: rem-calc(60);
            }
          }

          span {
            font-size: rem-calc(16);
            @include mq('lg') {
              font-size: rem-calc(19);
            }
          }
        }
      }
    }
  }

  &__indicators {
    @include reset-list;
    display: flex;
    justify-content: center;

    @include mq('lg') {
      display: none;
    }
  }

  &__indicators-slide {
    height: rem-calc(5);
    margin: 0 rem-calc(3);
    width: rem-calc(5);

    &.s-active {
      width: rem-calc(24);
    }
  }

  &__tooltip {
    margin-top: spacing('3');
  }

  .badge__container {
    margin-bottom: spacing('2');
  }

  &__hdln {
    margin-block: 0 spacing('0.5');
  }

  &__hdln,
  h4 {
    color: hsl(var(--grey-midnight));
  }

  &__info--small {
    font-size: rem-calc(12);
    line-height: normal;
    font-weight: $typography-weight-semi-bold;
  }
}
