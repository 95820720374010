@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';

.content {
  &--step {
    @include mq('md') {
      display: flex;

      > *:last-child {
        order: -1;
      }

      > * {
        margin: initial;
      }
    }
  }

  &--center {
    text-align: center;

    > * {
      margin-left: auto;
      margin-right: auto;
    }

    ul,
    ol {
      list-style-position: inside;
    }

    &\@md {
      @include mq('md') {
        text-align: center;

        > * {
          margin-left: auto;
          margin-right: auto;
        }

        ul,
        ol {
          list-style-position: inside;
        }
      }
    }
  }

  &--left {
    &\@md {
      @include mq('md') {
        text-align: inherit;

        > * {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  &--right {
    &\@md {
      @include mq('md') {
        text-align: right;

        > * {
          margin-left: 0;
          margin-right: 0;
        }
      }

      &-only {
        @include mq('md', map-get(map-get($breakpoints, 'lg'), 'max-width')) {
          text-align: right;

          > * {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }

  &--contain {
    padding-left: var(--offset);
    padding-right: var(--offset);
  }

  &--media-left {
    flex-direction: row;
  }

  &--media-right {
    flex-direction: row-reverse;
  }

  &--media-top {
    align-items: center;
    flex-direction: column;

    > * {
      flex: auto;
    }
  }
}

.text {
  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }
}
