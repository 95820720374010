@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import '../../subatoms/typography/typography_variables';

.bubble {
  --bubble-size: #{rem-calc(124)};
  --bubble-margin-top: 0;
  --bubble-margin-right: 0;
  --bubble-margin-left: 0;
  --bubble-offset-top: #{rem-calc(16)};
  --bubble-offset-right: #{rem-calc(8)};
  --bubble-offset-left: #{rem-calc(8)};
  --bubble-small-value-font-size: #{rem-calc(11)};
  --bubble-small-value-letter-spacing: #{rem-calc(-0.12)};
  --bubble-font-size: #{rem-calc(16)};
  --bubble-letter-spacing: #{rem-calc(-0.4)};
  --bubble-h-text-alignment: start;
  --bubble-v-text-alignment: start;

  &__wrap {
    width: fit-content;
    overflow: hidden;
  }

  display: flex;
  flex-direction: column;
  align-items: var(--bubble-h-text-alignment);
  justify-content: var(--bubble-v-text-alignment);
  margin: var(--bubble-margin-top) var(--bubble-margin-right) 0 var(--bubble-margin-left);
  padding-top: calc(var(--bubble-margin-top) * -1 + var(--bubble-offset-top));
  padding-right: calc(var(--bubble-margin-right) * -1 + var(--bubble-offset-right));
  padding-left: calc(var(--bubble-margin-left) * -1 + var(--bubble-offset-left));
  border-radius: 50%;
  aspect-ratio: 1;
  width: var(--bubble-size);
  font: $typography-weight-black var(--bubble-font-size) / 1 $typography-family-akko;
  letter-spacing: var(--bubble-letter-spacing);
  text-align: left;

  &__small-value {
    font-size: var(--bubble-small-value-font-size);
    font-weight: $typography-weight-bold;
    letter-spacing: var(--bubble-small-value-letter-spacing);
    line-height: 1;
  }

  // Small size bubble for mobile
  &--sm {
    --bubble-size: #{rem-calc(70)};
    --bubble-margin-top: #{rem-calc(-17)};
    --bubble-margin-left: #{rem-calc(-7)};
    --bubble-offset-top: #{rem-calc(12)}; //?
    --bubble-offset-left: #{rem-calc(6)};
    --bubble-offset-right: #{rem-calc(6)};

    &.bubble {
      &--secondary {
        --bubble-margin-right: #{rem-calc(-7)};
        --bubble-margin-left: 0;
        --bubble-h-text-alignment: end;
      }
    }
  }

  // Medium size bubble for mobile and desktop (normal offer tile default size)
  &--md {
    --bubble-size: #{rem-calc(92)};
    --bubble-margin-top: #{rem-calc(-21)};
    --bubble-margin-left: #{rem-calc(-11)};
    --bubble-offset-top: #{rem-calc(16)};
    --bubble-offset-left: #{rem-calc(8)};
    --bubble-offset-right: 0;
    --bubble-font-size: #{rem-calc(22)};

    @include mq('lg') {
      --bubble-size: #{rem-calc(135)};
      --bubble-margin-top: #{rem-calc(-31)};
      --bubble-margin-left: #{rem-calc(-26)};
      --bubble-offset-top: #{rem-calc(22)};
      --bubble-offset-left: #{rem-calc(16)};
      --bubble-small-value-font-size: #{rem-calc(13)};
      --bubble-font-size: #{rem-calc(25)};

    }

    &.bubble {
      &--secondary {
        --bubble-size: #{rem-calc(74)};
        --bubble-margin-top: #{rem-calc(-16)};
        --bubble-margin-right: #{rem-calc(-7)};
        --bubble-margin-left: 0;
        --bubble-offset-left: 0;
        --bubble-offset-right: #{rem-calc(6)};
        --bubble-font-size: #{rem-calc(16)};
        --bubble-h-text-alignment: end;

        @include mq('lg') {
          --bubble-size: #{rem-calc(116)};
          --bubble-margin-top: #{rem-calc(-28)};
          --bubble-margin-right: #{rem-calc(-13)};
          --bubble-margin-left: 0;
          --bubble-offset-top: #{rem-calc(22)};
          --bubble-offset-right: #{rem-calc(16)};
          --bubble-font-size: #{rem-calc(22)};
        }
      }
    }
  }

  // Large size bubble for mobile and desktop (highlight offer tile default size)
  &--lg {
    --bubble-size: #{rem-calc(124)};
    --bubble-margin-top: #{rem-calc(-32)};
    --bubble-margin-left: #{rem-calc(-16)};
    --bubble-offset-left: #{rem-calc(16)};
    --bubble-offset-right: 0;
    --bubble-small-value-font-size: #{rem-calc(14)};
    --bubble-font-size: #{rem-calc(26)};

    @include mq('lg') {
      --bubble-size: #{rem-calc(163)};
      --bubble-margin-top: #{rem-calc(-39)};
      --bubble-margin-left: #{rem-calc(-20)};
      --bubble-offset-top: #{rem-calc(24)};
      --bubble-offset-left: #{rem-calc(24)};
      --bubble-offset-right: 0;
      --bubble-small-value-font-size: #{rem-calc(16)};
      --bubble-font-size: #{rem-calc(33)};
    }

    &.bubble {
      &--secondary {
        --bubble-size: #{rem-calc(78)};
        --bubble-margin-top: #{rem-calc(-17)};
        --bubble-margin-left: 0;
        --bubble-margin-right: 0;
        --bubble-offset-top: #{rem-calc(16)};
        --bubble-offset-left: 0;
        --bubble-offset-right: 0;
        --bubble-small-value-font-size: #{rem-calc(11)};
        --bubble-font-size: #{rem-calc(20)};
        --bubble-h-text-alignment: center;

        @include mq('lg') {
          --bubble-size: #{rem-calc(143)};
          --bubble-margin-top: #{rem-calc(-54)};
          --bubble-offset-top: #{rem-calc(24)};
          --bubble-small-value-font-size: #{rem-calc(16)};
          --bubble-font-size: #{rem-calc(29)};
        }
      }
    }
  }

  // Extra large size bubble for mobile and desktop (used in offer details)
  &--xl {
    --bubble-size: #{rem-calc(156)};
    --bubble-margin-top: #{rem-calc(-28)};
    --bubble-margin-left: #{rem-calc(-22)};
    --bubble-offset-top: #{rem-calc(22)};
    --bubble-offset-left: #{rem-calc(24)};
    --bubble-offset-right: 0;
    --bubble-small-value-font-size: #{rem-calc(18)};
    --bubble-font-size: #{rem-calc(35)};

    @include mq('lg') {
      --bubble-size: #{rem-calc(275)};
      --bubble-margin-top: #{rem-calc(-46)};
      --bubble-margin-left: #{rem-calc(-40)};
      --bubble-offset-top: #{rem-calc(56)};
      --bubble-offset-left: #{rem-calc(24)};
      --bubble-offset-right: 0;
      --bubble-small-value-font-size: #{rem-calc(30)};
      --bubble-font-size: #{rem-calc(50)};
    }

    &.bubble {
      &--secondary {
        --bubble-size: #{rem-calc(124)};
        --bubble-margin-top: #{rem-calc(-37)};
        --bubble-margin-left: 0;
        --bubble-offset-left: 0;
        --bubble-small-value-font-size: #{rem-calc(14)};
        --bubble-font-size: #{rem-calc(26)};
        --bubble-h-text-alignment: center;

        @include mq('lg') {
          --bubble-size: #{rem-calc(185)};
          --bubble-small-value-font-size: #{rem-calc(23)};
          --bubble-font-size: #{rem-calc(45)};
        }
      }
    }
  }
}
