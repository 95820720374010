@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/reset/list/reset-list-mixins';

.market-list {
  @include reset-list();

  .market-tile {
    height: 100%;
  }

  .market-finder--radio & {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--gutter);

    @include mq('md') {
      grid-template-columns: 1fr 1fr;
    }

    > li {
      margin-block: 0;
    }
  }
}
