@import 'headlines_mixins';

h1,
.h1 {
  @include h1();
}

h2,
.h2 {
  @include h2();
}

h3,
.h3 {
  @include h3();
}

h4,
.h4 {
  @include h4();
}

h5,
.h5 {
  @include h5();
}

h6,
.h6 {
  @include h5();
}

.h7 {
  @include h7();
}
