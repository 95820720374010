@use 'sass:math';
@import 'scss-mixins-functions-variables/scss/reset/list/reset-list-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';

.image-list {
  $spacing-horizontal: calc(#{spacing('1')} / 2);
  $spacing-vertical: spacing('1');

  @include reset-list();
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(#{$spacing-horizontal} * -1);
  margin-right: calc(#{$spacing-horizontal} * -1);

  > li {
    display: flex;
    margin: $spacing-vertical $spacing-horizontal;
    text-align: center;
  }

  img {
    max-height: rem-calc(40);
    width: auto;
  }
}
