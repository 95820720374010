@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'input';

.market-finder {
  position: relative;
  $input-offset-top: #{spacing('3')};

  @include mq('lg') {
    $input-offset-top: #{spacing('3')};
  }

  &__search {
    position: relative;

    &-wrapper,
    &-wrapper--placeholder {
      position: sticky;
      top: $input-offset-top;

      @include mq('lg') {
        top: calc(var(--sticky-header-height) + #{$input-offset-top});
      }
    }

    &-wrapper {
      margin-block: spacing('4') spacing('2');
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        "search-field"
        "search-info";
      align-items: end;

      @include mq('md') {
        grid-template-columns: 1fr 2fr 1fr;
        grid-template-areas: "search-info search-field .";
        column-gap: spacing('1');
      }

      @include mq('xl') {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "search-info search-field .";
        margin-block: spacing('6') spacing('4');
      }

      &--placeholder {
        order: 0;
        min-height: rem-calc(88);

        @include mq('lg') {
          min-height: rem-calc(122);
        }

        .compound-input__button {
          pointer-events: none;
        }
      }
    }

    &-bar {
      grid-area: search-field;
    }
  }

  &__input {
    width: 100%;
    max-width: 520px;

    @include mq('lg') {
      max-width: 610px;
    }
  }

  &__label {
    display: inline-flex;
    margin-bottom: rem-calc(2);
    margin-left: rem-calc(20);
    padding-inline: rem-calc(4);
    border-radius: rem-calc(4);
    opacity: 0;

    @include mq('lg') {
      margin-left: rem-calc(30);
    }
  }

  &__detail {
    @include mq('lg') {
      padding-top: spacing('8');
    }
  }

  &__results-container {
    order: 1;
  }

  &__btn-toggle--teleport {
    order: 2;
  }

  &__list {
    margin-top: spacing('3');

    @include mq('lg') {
      margin-top: spacing('4');
    }

    & > * {
      margin: 0;
    }
  }

  &__list--predefined {
    list-style: none;
  }
}
