/*
    HSLA (hue, saturation, lightness, alpha).

    Initial values represent BLACK and these values (--hue, --saturation, --lightness, --alpha-opacity) are adjusted for each color

    Each color can be used in a component like this:
    - without alpha opacity:
    color: hsl(var(--red))

    - with alpha opacity:
    --alpha-opacity: 0.5;
    color: hsl(var(--red) / var(--alpha-opacity))

    OR update initial values inside the component
    --hue: 350;
    --saturation: 50%;
    --lightness: 30%;
    --alpha-opacity: 0.5;
    color: hsl(var(--hue) var(--saturation) var(--lightness) / var(--alpha-opacity)) which results in a custom color
*/

:root,
::backdrop {
  // initial values
  --hue: 0;
  --saturation: 0%;
  --lightness: 0%;
  --alpha-opacity: 1;
  // primary colors
  --black: var(--hue) var(--saturation) var(--lightness); // hsl: '0 0% 0%' / hex: #000
  --white: var(--hue) var(--saturation) calc(var(--lightness) + 100%); // hsl: '0 0% 100%' / hex: #fff
  --red: var(--hue) calc(var(--saturation) + 82%) calc(var(--lightness) + 44%); // hsl: '0 82% 44%' / hex: #cd1414
  --yellow: calc(var(--hue) + 49) calc(var(--saturation) + 100%) calc(var(--lightness) + 50%); // hsl: 49 100% 50% / hex: #ffd200
  // secondary colors
  // red tones
  --red-burgund: calc(var(--hue) + 352) calc(var(--saturation) + 73%) calc(var(--lightness) + 22%); // hsl: 352 73% 22% / hex: #660033
  --red-coral: calc(var(--hue) + 12) calc(var(--saturation) + 100%) calc(var(--lightness) + 44%); // hsl: 12 100% 44% / hex: #BF5545
  --red-thunderbird: var(--hue) calc(var(--saturation) + 82%) calc(var(--lightness) + 40%); // hsl: 0 82% 40% / hex: #ba1212
  // orange and yellow tones
  --orange-carrot: calc(var(--hue) + 23) calc(var(--saturation) + 100%) calc(var(--lightness) + 57%); // hsl: 23 100% 57% / hex: #D14900
  --orange-dark: calc(var(--hue) + 21) calc(var(--saturation) + 100%) calc(var(--lightness) + 34%); // hsl: 21 100% 34% / hex: #AC3C00
  --dark-gold: calc(var(--hue) + 49) calc(var(--saturation) + 100%) calc(var(--lightness) + 27%); // hsl: 49 100% 27% / hex: #8A7000
  // blue tones
  --blue-petrol: calc(var(--hue) + 184) calc(var(--saturation) + 100%) calc(var(--lightness) + 26%); // hsl: 184 100% 26% / hex: #007B84
  --blue-denim: calc(var(--hue) + 210) calc(var(--saturation) + 50%) calc(var(--lightness) + 40%); // hsl: 210 50% 40% / hex: #336699
  --blue-mediterranean: calc(var(--hue) + 195) calc(var(--saturation) + 74%) calc(var(--lightness) + 60%); // hsl: 195 74% 60% / hex: #007AB8
  --blue-payback: calc(var(--hue) + 215) calc(var(--saturation) + 100%) calc(var(--lightness) + 33%); // hsl: 215 100% 33% / hex: #0046AA
  --blue-vegan: calc(var(--hue) + 193) calc(var(--saturation) + 41%) calc(var(--lightness) + 39%); // hsl: 193 41% 39% / hex: #3b7b8d
  // green tones
  --green-accessibility: calc(var(--hue) + 138) calc(var(--saturation) + 100%) calc(var(--lightness) + 33%); // hsl: 138 100% 33% / hex: #008528
  --green-sustainability: calc(var(--hue) + 157) calc(var(--saturation) + 95%) calc(var(--lightness) + 32%); // hsl: 157 95% 32% / hex: #42806C
  --green-foerderpenny: calc(var(--hue) + 152) calc(var(--saturation) + 69%) calc(var(--lightness) + 24%); // hsl: 152 69% 24% / hex: #136941
  --light-green-foerderpenny: calc(var(--hue) + 157) calc(var(--saturation) + 93%) calc(var(--lightness) + 32%); // hsl: 157 93% 32% / hex: #069d62
  --green-plant: calc(var(--hue) + 161) calc(var(--saturation) + 32%) calc(var(--lightness) + 38%); // hsl: 161 32% 38% / hex: #42806C
  // brown tones
  --brown-chocolate: calc(var(--hue) + 11) calc(var(--saturation) + 17%) calc(var(--lightness) + 33%); // hsl: 11 17% 33% / hex: #624b46
  --brown-espresso: calc(var(--hue) + 12) calc(var(--saturation) + 20%) calc(var(--lightness) + 50%); // hsl: 12 20% 50% / hex: #936B62
  --brown-sand: calc(var(--hue) + 45) calc(var(--saturation) + 40%) calc(var(--lightness) + 69%); // hsl: 45 40% 69% / hex: #73795D
  // purple tones
  --purple-orchid: calc(var(--hue) + 300) calc(var(--saturation) + 52%) calc(var(--lightness) + 32%); // hsl: 300 52% 32% / hex: #7a277a
  --purple-lilac: calc(var(--hue) + 300) calc(var(--saturation) + 20%) calc(var(--lightness) + 50%); // hsl: 300 20% 50% / hex: #936293
  --purple-fuchsia: calc(var(--hue) + 334) calc(var(--saturation) + 66%) calc(var(--lightness) + 56%); // hsl: 334 66% 56% / hex: #D5347A
  --purple-rouge: calc(var(--hue) + 349) calc(var(--saturation) + 54%) calc(var(--lightness) + 60%); // hsl: 349 54% 60% / hex: #C8465E
  // grey tones
  --grey-midnight: calc(var(--hue) + 216) calc(var(--saturation) + 17%) calc(var(--lightness) + 30%); // hsl: 216 17% 30% / hex: #404b5b
  --grey-stone: calc(var(--hue) + 198) calc(var(--saturation) + 10%) calc(var(--lightness) + 34%); // hsl: 198 10% 34% / hex: #4e5a5f
  --grey-wild-sand: var(--hue) var(--saturation) calc(var(--lightness) + 96%); // hsl: 0 0% 96% / hex: #f6f6f6
  --grey-cobble-stone: var(--hue) var(--saturation) calc(var(--lightness) + 92%); // hsl: 0 0% 96% / hex: #eaeaea
  // black tones
  --black-butchers: var(--hue) var(--saturation) calc(var(--lightness) + 13%); // hsl: 0 0% 13% / hex: #202020
  // brands
  --black-butchers: var(--hue) var(--saturation) calc(var(--lightness) + 13%); // hsl: 0 0% 13% / hex: #202020
  --blue-ready: calc(var(--hue) + 200) calc(var(--saturation) + 100%) calc(var(--lightness) + 40%); // hsl: 200 100% 40% / hex: #0089cc
  --red-einfachsparen: var(--hue) calc(var(--saturation) + 82%) calc(var(--lightness) + 44%); // hsl: '0 82% 44%' / hex: #cd1414
  --green-naturgut: calc(var(--hue) + 157) calc(var(--saturation) + 95%) calc(var(--lightness) + 32%); // hsl: 157 95% 32% / hex: #049D62
  --green-sanfabio: calc(var(--hue) + 98) calc(var(--saturation) + 48%) calc(var(--lightness) + 52%); // hsl: 98 48% 52% / hex: #75c04a
  --green-today: calc(var(--hue) + 180) calc(var(--saturation) + 100%) calc(var(--lightness) + 30%); // hsl: 180 100% 30% / hex: #008080
  // wines
  --green-sparkling: calc(var(--hue) + 121) calc(var(--saturation) + 20%) calc(var(--lightness) + 50%); // hsl: 121 20% 50% / hex: #659966
  --orange-spirits: calc(var(--hue) + 36) calc(var(--saturation) + 100%) calc(var(--lightness) + 50%); // hsl: 124 36% 32% / hex: #ff9901
}
