@use 'sass:math';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';

@import 'grid_variables';

@mixin make-container {
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: #{$max-width};
  @if $support-custom-properties-fallback {
    padding-left: $grid-fallback-offset;
    padding-right: $grid-fallback-offset;
  }
  padding-left: var(--offset);
  padding-right: var(--offset);
}

// Public mixin for the grid row (container)
@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  @if ($support-custom-properties-fallback) {
    margin: 0 calc(#{$grid-fallback-gutter} / -2) #{$grid-fallback-vertical-gutter * -1};
  }
  margin: 0 calc(var(--gutter) / -2) calc(var(--gutter-y) * -1);
}

// Public mixin for the base grid column definition
@mixin make-col() {
  flex: 0 0 100%;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  min-height: 1px; // Prevent collapsing
  @if ($support-custom-properties-fallback) {
    padding: 0 calc(#{$grid-fallback-gutter} / 2) $grid-fallback-vertical-gutter;
  }
  padding: 0 calc(var(--gutter) / 2) var(--gutter-y);
  width: 100%;
}

// Public mixin for column spanning
@mixin make-col-span($span: $grid-fallback-columns, $columns: $grid-fallback-columns) {
  flex-basis: percentage(math.div($span, $columns));
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage(math.div($span, $columns));
}

// Public mixin for the base grid column offset definition
@mixin make-offset() {
  margin-left: 0;
}

// Public mixin for column offset
@mixin make-col-offset($span: $grid-fallback-columns, $columns: $grid-fallback-columns) {
  margin-left: percentage(math.div($span, $columns));
}
