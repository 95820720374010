@import '../grid/grid_variables';

meta {
  &[id^='css-'] {
    font-family: ' ';
  }
}

#css {
  &-viewport-width {
    @each $breakpoint-key, $breakpoint-values in $breakpoints {
      $min-width: map-get($breakpoint-values, 'min-width');
      @if ($min-width) {
        @media (min-width: $min-width) {
          font-family: 'min-width:#{$min-width}';
        }
      } @else {
        font-family: 'min-width:0';
      }

      @media (min-width: $min-width) {
        &-code {
          font-family: $breakpoint-key;
        }
      }
    }
  }

  &-orientation {
    font-family: 'unknown';

    @media screen and (orientation: portrait) {
      font-family: 'portrait';
    }

    @media screen and (orientation: landscape) {
      font-family: 'landscape';
    }
  }

  &-resolution {
    font-family: 'min-resolution:unknown';

    &-code {
      font-family: 'unknown';
    }

    @media screen and (min-resolution: 1dppx) {
      font-family: 'min-resolution:1ppx';

      &-code {
        font-family: 'sd';
      }
    }

    @media screen and (min-resolution: 2dppx) {
      font-family: 'min-resolution:2ppx';

      &-code {
        font-family: 'hd';
      }
    }
  }
}
