//
// Forms
//

// Bootstrap
label {
	// BS: Allow labels to use `margin` for spacing.
	display: inline-block;
}

// Bootstrap
// Remove the default `border-radius` that macOS Chrome adds.
// Details at https://github.com/twbs/bootstrap/issues/24093
button {
	border-radius: 0;

	// Work around a Firefox/IE bug where the transparent `button` background
	// results in a loss of the default `button` focus styles.
	// Credit: https://github.com/suitcss/base/
	&:focus {
		outline: 1px dotted;
		// sass-lint:disable no-vendor-prefixes no-duplicate-properties
		outline: 5px auto -webkit-focus-ring-color;
	}
}

// Normalize doesn't inherit the settings, but rather sets them to fixed values. seems like a strange choice to me
input,
button,
select,
optgroup,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	margin: 0; // BS & N: Remove the margin in Firefox and Safari
}

button,
input {
	overflow: visible; // BS & N: Show the overflow in Edge
}

button,
select {
	text-transform: none; // BS & N: Remove the inheritance of text transform in Firefox
}

// Bootstrap & Normalize
// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
html [type="button"], // 1
[type="reset"],
[type="submit"] {
	appearance: button; // 2
}

// Bootstrap & Normalize
// Remove inner border and padding from Firefox.
button,
[type="button"],
[type="reset"],
[type="submit"] {
	&::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}
}

// Normalize: Restore the focus styles unset by the previous rule.
// Bootrap doesn't restore the outline.
button,
[type="button"],
[type="reset"],
[type="submit"] {
	&:-moz-focusring {
		outline: 1px dotted ButtonText;
	}
}

// Fieldset and Legend normlizations don't agree with me so: https://thatemil.com/blog/2015/01/03/reset-your-fieldset/

// BS & N 1. Correct the text wrapping in Edge and IE.
// mein 2. - Not entirely elegant, but necessary solution to be able to freely style the legend as if it were a normal block element. Requires that there be another node after legend.

legend {
	color: inherit; // BS & N: Correct the color inheritance from `fieldset` elements in IE.
	display: table; // article & 1
	float: left; // 2
	max-width: 100%; // 1
	padding: 0;
	white-space: normal; // 1
	width: 100%; // 2

	// 2
	+ * {
		clear: both;
	}
}

fieldset {
	border: 0;
	margin: 0;
	min-width: 0;
	padding: 0.01em 0 0;

	// sass-lint:disable space-after-colon
	@at-root body:not(:-moz-handler-blocked) & {
		display: table-cell;
	}
}

// 1. Normalize: Add the correct display in IE 9-. - not a relevant browser for me
// 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
progress {
	vertical-align: baseline; // BS & N: 2
}

textarea {
	overflow: auto; // BS & N: Remove the default vertical scrollbar in IE.
	resize: vertical; // BS & HTML5 BP: Textareas should really only resize vertically so they don't break their (horizontal) containers.
}

// BS & N: Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"] {
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		height: auto;
	}
}

[type="search"] {
	// BS: This overrides the extra rounded corners on search inputs in iOS so that our
	// `.form-control` class can properly style them. Note that this cannot simply
	// be added to `.form-control` as it's not specific enough. For details, see
	// https://github.com/twbs/bootstrap/issues/11586.
	appearance: none; // N: Correct the odd appearance in Chrome and Safari. - TODO
	outline-offset: -2px; // BS & N: Correct the outline style in Safari.
}

// TODO: type=password

// Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
// DON'T AGREE WITH THIS ... PROGRESSIVE ENHANCEMENT
// [type="search"]::-webkit-search-cancel-button,
// [type="search"]::-webkit-search-decoration {
//   -webkit-appearance: none;
// }

// Bootstrap & Normalize
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
::-webkit-file-upload-button {
	appearance: button; // 1
	font: inherit; // 2
}
