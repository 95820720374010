/* -------------------- *\
	Fallback system fonts
\* -------------------- */

// OpenSans Fallback - Use Arial as fallback font for iOS and Windows
@font-face {
  font-family: 'OS-Fallback-Arial';
  src: local('Arial');
  size-adjust: 105.4%;
  ascent-override: 102%;
  descent-override: 27%;
  line-gap-override: 0%;
}

// OpenSans Fallback - Use Roboto as fallback font for Android
@font-face {
  font-family: 'OS-Fallback-Roboto';
  src: local('Roboto');
  size-adjust: 110%;
  ascent-override: 95%;
}

// Akko Fallback - Use Arial as fallback font for iOS and Windows
@font-face {
  font-family: 'Akko-Fallback-Arial';
  src: local(Arial);
  size-adjust: 96%;
  ascent-override: 97%;
  descent-override: 28%;
  line-gap-override: 0%;
}

//Akko Fallback - Use Roboto as fallback font for Android
@font-face {
  font-family: 'Akko-Fallback-Roboto';
  src: local(Roboto);
  size-adjust: 96%;
  ascent-override: 97%;
}
