@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';
@import '../../subatoms/grid/grid_variables';

.recipe-filter {
  $filter-btn-offset: #{spacing('12')};

  &__filter-bar {
    position: sticky;
    top: 0;
    z-index: 1;

    @include mq('lg') {
      top: calc(var(--sticky-header-height) - #{rem-calc(1)});
    }

    .l-container {
      @include mq($max-width: 'md') {
        margin: 0;
        padding: 0;
      }
    }
  }

  &__row {
    display: block;
    margin: 0;
    padding: 0;

    @include mq('lg') {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: nowrap;
      align-items: center;
    }
  }

  &__swiper {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    &.swiper {
      @include mq('lg') {
        padding-left: spacing('3');
      }

      .swiper-wrapper {
        display: flex;
      }
    }

    &-container {
      position: relative;
      width: 100%;
      overflow: hidden;

      &:after,
      &:before {
        display: none;
        content: '';
        width: rem-calc(70);
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 2;

        @include mq('lg') {
          display: block;
        }
      }

      &:before {
        left: rem-calc(-30);
      }

      &:after {
        right: rem-calc(-30);
      }

    }

    .swiper-slide {
      width: auto;
      padding: spacing('2') 0;

      @include mq('lg') {
        display: flex;
        align-items: center;

        &:first-child {
          .btn {
            margin-left: spacing('3');
          }
        }

        &:last-child {
          .btn {
            margin-right: spacing('5');
          }
        }
      }

      &:first-child {
        .btn {
          margin-left: rem-calc(12);
        }
      }

      &:last-child {
        .btn {
          margin-right: rem-calc(12);
        }
      }
    }

    &-nav {
      position: relative;
      display: none;
      justify-content: space-between;
      align-items: center;
      padding-left: spacing('5');

      @include mq('lg') {
        display: flex;
      }

      .swiper-button-prev,
      .swiper-button-next {
        position: static;
        width: rem-calc(30);
        height: rem-calc(30);
        margin-top: 0;
        border-radius: rem-calc(3);

        &:after {
          font-size: rem-calc(14);
        }
      }

      .swiper-button-prev {
        margin-right: rem-calc(22);
      }
    }

    .btn {
      display: flex;
      column-gap: rem-calc(10);
      align-items: center;
      min-width: auto;
      margin: 0 rem-calc(4);
      padding: rem-calc(8) rem-calc(20);
      border-width: rem-calc(1);

      .icon {
        font-size: rem-calc(20);
      }
    }
  }

  &__menu {
    display: none;

    &-toggle {
      position: absolute;
      bottom: calc(#{$filter-btn-offset} * -1);
      left: 50%;
      transform: translateX(-50%);
      min-width: rem-calc(152);
      width: rem-calc(152);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding-left: 0;
      padding-right: 0;

      @include mq('lg') {
        position: relative;
        top: 0;
        left: 0;
        bottom: 0;
        transform: translateX(0);
        margin-bottom: 0;
      }

      &--mobile {
        @include mq('lg') {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include mq('lg') {
          display: inline-flex;
        }
      }
    }
  }
}
