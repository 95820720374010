@import 'scss-mixins-functions-variables/scss/reset/list/reset-list-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';

ul {
  padding-left: 1.25em;
}

li {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.inline-list {
  @include reset-list();
  display: inline-flex;

  > li {
    margin: 0 rem-calc(4);
  }
}

.reset-list {
  @include reset-list();
}
