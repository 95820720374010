@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/elevation/elevation_variables';

.pill {
  align-items: stretch;
  background: hsl(var(--white));
  border-radius: 9rem;
  box-shadow: map-get($elevations, '08_dp_b_1');
  color: inherit;
  display: inline-flex;
  font-size: rem-calc(18);
  font-weight: $typography-weight-medium;
  line-height: calc(24.1 / 18);
  max-width: 100%;
  overflow: hidden;
  text-decoration: none;

  &__text {
    flex: 1 1 auto;
    min-width: 0;
    overflow: hidden;
    padding: rem-calc(12) rem-calc(24);
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--centered {
      @extend .pill__text;
      text-align: center;
    }
  }

  &__image {
    position: relative;
    width: 75px;

    > img {
      height: 100%;
      object-fit: cover;
      object-position: left;
      width: 100%;
    }
  }

  &__dot {
    align-items: center;
    color: transparent;
    display: flex;
    padding: rem-calc(12) rem-calc(12) rem-calc(12) 0;

    &::before {
      background: currentColor;
      border-radius: 50%;
      box-shadow: inset 0 1px 3px 0 rgba(45, 45, 45, 0.5);
      content: '';
      display: block;
      height: 1rem;
      width: 1rem;
    }
  }
}
