.video {
  &__container {
    --aspect-ratio-portrait: 4 / 5;
    --aspect-ratio-landscape: 16 / 9;

    position: relative;
    isolation: isolate;

    &--portrait {
      aspect-ratio: var(--aspect-ratio-portrait);
    }

    &--landscape {
      aspect-ratio: var(--aspect-ratio-landscape);
    }
  }

  img,
  video {
    position: absolute;
    width: 100%;
  }

  video {
    bottom: 0;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }

  &.video--portrait {
    video {
      height: 100%;
    }
  }

  &__poster {
    position: absolute;
    inset: 0;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  &__cover {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
  }
}
