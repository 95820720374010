@import 'scss-mixins-functions-variables/scss/layout/visually-hidden-mixins';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import '../grid/grid_variables';

.l-visually-hidden {
  @include visually-hidden();
  padding: 0 !important;
}

.hidden {
  display: none !important;
}

// Temporary ... from foundation
$bp-md: map-get(map-get($breakpoints, 'md'), 'min-width');
$bp-lg: map-get(map-get($breakpoints, 'lg'), 'min-width');
$bp-xl: map-get(map-get($breakpoints, 'xl'), 'min-width');

@media screen and (max-width: ($bp-md * 0.9999)) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: $bp-md) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: $bp-md) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: ($bp-md * 0.9999)) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: $bp-md) and (max-width: ($bp-lg * 0.9999)) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: ($bp-md * 0.9999)), screen and (min-width: $bp-lg) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: $bp-lg) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: ($bp-lg * 0.9999)) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: $bp-lg) and (max-width: ($bp-xl * 0.9999)) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: ($bp-lg * 0.9999)), screen and (min-width: $bp-xl) {
  .show-for-large-only {
    display: none !important;
  }
}
