@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/math/rounding-functions';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/typography/typography_variables';
@import '../../atoms/semantic/headlines/headlines_mixins';
@import '../../subatoms/vertical-spacing/vertical-spacing_functions';

.validity-indicator {
  --bg-color: hsl(var(--white));
  --bg-reverse-color: hsl(var(--red));
  --text-color: hsl(var(--grey-midnight));
  --text-reverse-color: hsl(var(--white));
  --text-highlight-color: hsl(var(--grey-midnight));
  --line-color: hsl(var(--grey-midnight));
  --indicator-border: #{rem-calc(20)};
  --indicator-transition-duration: 0.2s;
  --indicator-transition-timing-function: ease-in-out;
  --indicator-transition-delay: 0.3s;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $typography-family-akko;
  font-size: rem-calc(12);
  font-weight: $typography-weight-medium;
  line-height: decimal-round(calc(20 / 12), 2);
  padding-bottom: rem-calc(60);
  text-align: center;
  background-color: var(--bg-color);

  @include mq('lg') {
    padding-bottom: spacing('12');
  }

  &--next-week {
    --bg-color: hsl(var(--red));
    --bg-reverse-color: hsl(var(--white));
    --text-color: hsl(var(--white));
    --text-reverse-color: hsl(var(--grey-midnight));
    --text-highlight-color: hsl(var(--white));
    --line-color: hsl(var(--white));
  }

  &__hdln {
    @include h1();
    color: var(--text-color);
    margin-block: 0 rem-calc(8);

    @include mq('lg') {
      margin-bottom: rem-calc(19);
    }
  }

  &__subline {
    @include h6();
    color: var(--text-color);
    margin-block: 0;

    @include mq('lg') {
      @include h5();
    }
  }

  &__label {
    display: inline-block;
    font: $typography-weight-bold #{rem-calc(11)} / decimal-round(calc(13 / 11), 3) $typography-family-sans;
    background-color: var(--text-highlight-color);
    color: var(--text-reverse-color);
    margin-block: spacing('5') rem-calc(13);
    padding: rem-calc(4) rem-calc(6);
    border-radius: rem-calc(4);

    @include mq('lg') {
      margin-block: spacing('10') rem-calc(16);
    }
  }

  &__days {
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    list-style: none;
    padding: 0;
    margin-block: rem-calc(17);
    width: rem-calc(318);
    height: rem-calc(33);

    @include mq('lg') {
      width: rem-calc(360);
    }

    &::before {
      content: '';
      position: absolute;
      width: 0;
      margin: 0 auto;
      inset: 0;
      display: flex;
      grid-column-start: calc(var(--indicator-start, 0) + 1);
      grid-column-end: calc(var(--indicator-end, 0) + 2);
      border-radius: var(--indicator-border, 0);
      background-color: var(--bg-reverse-color);
      transition: width var(--indicator-transition-duration) var(--indicator-transition-timing-function) var(--indicator-transition-delay);
    }

    &.show-animation {
      &::before {
        width: 100%;
      }
      .validity-indicator__day {
        &--start,
        &--middle {
          color: var(--text-reverse-color);
        }
      }
    }
  }

  &__day {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-block: 0;
    font: $typography-weight-bold #{rem-calc(11)} / 1 $typography-family-akko;
    color: var(--text-color);
    transition: color var(--indicator-transition-duration) var(--indicator-transition-timing-function) var(--indicator-transition-delay);

    @include mq('lg') {
      font-size: rem-calc(13);
    }
  }

  &__desc {
    font: $typography-weight-semi-bold #{rem-calc(15)} / decimal-round(calc(21 / 15), 3) $typography-family-sans;
    color: var(--text-color);
    margin-top: spacing('5');

    @include mq('lg') {
      font-size: rem-calc(20);
      line-height: decimal-round(calc(28 / 20), 3);
      margin-top: spacing('10');
    }

    & > * {
      margin: 0;
    }
  }

  &__separator {
    width: 100%;
    border-bottom: rem-calc(1) dashed var(--line-color);
    opacity: 0.5;
  }
}
