@import 'scss-mixins-functions-variables/scss/math/rounding-functions';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';
@import 'scss-mixins-functions-variables/scss/typography/font-weight-variables';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import '../../subatoms/color/color_functions';
@import '../../subatoms/elevation/elevation_variables';
@import '../../subatoms/typography/typography_variables';
@import './button-states-mixin';

$page-sector-themes: color-themes();
$button-side-padding: rem-calc(64);
$button-side-padding-mobile: rem-calc(32);

.btn {
  --btn-border-radius: #{rem-calc(25)};

  position: relative;
  border: rem-calc(2) solid transparent; // need this for focus state
  border-radius: var(--btn-border-radius);
  cursor: pointer;
  display: inline-block;
  font: $typography-weight-medium #{rem-calc(15)} / decimal-round(calc(24 / 15), 3) $typography-family-akko;
  max-width: 100%;
  min-width: rem-calc(240);
  overflow: hidden;
  padding: rem-calc(10) $button-side-padding-mobile;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis; // AkkoForPenny doesn't have a correct ellipsis (…), rather a simple period (.)
  white-space: nowrap;
  background-position: center;
  transition: box-shadow 0.1s, background 0.8s;

  @include mq('sm') {
    padding: rem-calc(10) $button-side-padding;
  }

  @include button-active(var(--btn-border-radius));

  &[disabled] {
    opacity: 0.6;
  }

  &--primary,
  &--secondary,
  &--white {
    margin-bottom: rem-calc(14);
  }

  &--primary {
    background: hsl(var(--red));
    box-shadow: 0 5px 5px -3px transparentize($penny-red, 0.8), 0 3px 14px 2px transparentize($penny-red, 0.88),
      0 8px 10px 1px transparentize($penny-red, 0.86);
    color: hsl(var(--white));

    &.t {
      @each $theme-name, $theme-value in $page-sector-themes {
        &-bg--#{$theme-name} {
          @if $theme-value != $penny-white {
            box-shadow: 0 5px 5px -3px transparentize($theme-value, 0.8),
              0 3px 14px 2px transparentize($theme-value, 0.88), 0 8px 10px 1px transparentize($theme-value, 0.86);
          }

          &:hover {
            @if $theme-value != $penny-white {
              box-shadow: 0 5px 5px -3px transparentize($theme-value, 0.7),
                0 2px 1px -1px transparentize($theme-value, 0.76), 0 1px 1px 0 transparentize($theme-value, 0.7);
            } @else {
              box-shadow: 0 2px 1px -1px transparentize($penny-red, 0.8), 0 1px 1px 0 transparentize($penny-red, 0.92),
                0 1px 3px 0 transparentize($penny-red, 0.8);
            }
          }

          &:focus,
          &.focused {
            box-shadow: inset 0 0 0 rem-calc(2) $theme-value, inset 0 0 0 rem-calc(4) hsl(var(--white));

            @include button-focus-blue-outline();
          }
        }
      }
    }
  }

  &--secondary,
  &--white {
    background: hsl(var(--yellow));
    box-shadow: 0 8px 10px 1px transparentize($penny-yellow, 0.86), 0 3px 14px 2px transparentize($penny-yellow, 0.88),
      0 5px 5px -3px transparentize($penny-yellow, 0.8);
    color: hsl(var(--red));

    &:hover {
      box-shadow: 0 5px 5px -3px transparentize($penny-yellow, 0.7), 0 2px 1px -1px transparentize($penny-yellow, 0.76),
      0 1px 1px 0 transparentize($penny-yellow, 0.7);
    }

    &:focus,
    &.focused {
      @include button-focus-blue-outline();
    }
  }

  &--white {
    background: hsl(var(--white));
    box-shadow: 0 5px 5px -3px transparentize($penny-red, 0.8), 0 3px 14px 2px transparentize($penny-red, 0.88),
      0 8px 10px 1px transparentize($penny-red, 0.86);
    color: hsl(var(--red));

    &:hover {
      box-shadow: 0 2px 1px -1px transparentize($penny-red, 0.8), 0 1px 1px 0 transparentize($penny-red, 0.99),
      0 1px 3px 0 transparentize($penny-red, 0.8);
    }
  }

  &--text {
    color: hsl(var(--red));
    font-size: rem-calc(16);
    min-width: 0;
    padding-right: calc(#{$button-side-padding-mobile} - #{rem-calc(16)});

    @include mq('sm') {
      padding-right: calc(#{$button-side-padding} - #{rem-calc(16)});
    }

    .icon {
      font-size: rem-calc(24);
      margin-top: rem-calc(-2);
    }

    &.t {
      @each $theme-name, $theme-value in $page-sector-themes {
        &-color--#{$theme-name} {
          border: rem-calc(1) solid transparent;

          &:hover {
            background-color: transparentize($theme-value, 0.96);
            border-radius: rem-calc(24);
          }

          &:focus,
          &.focused {
            box-shadow: inset 0 0 0 rem-calc(2) $theme-value, inset 0 0 0 rem-calc(4) hsl(var(--white));

            @include button-focus-blue-outline();
          }
        }
      }
    }
  }

  &--outlined {
    border-style: solid;
    border-width: rem-calc(1);
    color: hsl(var(--red));
    border-color: hsl(var(--red));
    background-color: transparent;

    &.t {
      @each $theme-name, $theme-value in $page-sector-themes {
        &-color--#{$theme-name} {
          color: $theme-value;
          border-color: $theme-value;

          @include mq('lg') {
            &:hover {
              background-color: $theme-value;
              color: hsl(var(--white));

              @if $theme-value == $penny-white {
                color: hsl(var(--red));
              } @else if $theme-value == $penny-yellow {
                color: hsl(var(--red));
              } @else {
                color: hsl(var(--white));
              }
            }
          }

          &:focus,
          &.focused {
            box-shadow: inset 0 0 0 rem-calc(2) $theme-value, inset 0 0 0 rem-calc(4) hsl(var(--white));

            @include button-focus-blue-outline();

            @if $theme-value == $penny-white {
              color: hsl(var(--red));
            } @else if $theme-value == $penny-yellow {
              color: hsl(var(--red));
            } @else {
              color: hsl(var(--white));
            }
          }

          &:active {
            background-color: darken($theme-value, 20%);

            @if $theme-value == $penny-white {
              color: hsl(var(--red));
            } @else if $theme-value == $penny-yellow {
              color: hsl(var(--red));
            } @else {
              color: hsl(var(--white));
            }
          }
        }
      }
    }
  }

  &--round {
    --btn-border-radius: 50%;

    display: inline-flex;
    font-size: rem-calc(13);
    background-color: hsl(var(--white));
    box-shadow: 0 8px 10px 1px transparentize($penny-red, 0.86), 0 3px 14px 2px transparentize($penny-red, 0.88), 0 5px 5px -3px transparentize($penny-red, 0.80);
    border: 0;
    min-width: auto;
    padding: rem-calc(13);
    cursor: pointer;

    &.t {
      @each $theme-name, $theme-value in $page-sector-themes {
        &-bg--#{$theme-name} {
          @if $theme-value != $penny-white {
            box-shadow: 0 5px 5px -3px transparentize($theme-value, 0.8),
            0 3px 14px 2px transparentize($theme-value, 0.88), 0 8px 10px 1px transparentize($theme-value, 0.86);
          } @else {
            box-shadow: 0 5px 5px -3px transparentize($theme-value, 0.8),
            0 3px 14px 2px transparentize($penny-red, 0.88), 0 8px 10px 1px transparentize($penny-red, 0.86);
          }

          &:hover {
            @if $theme-value != $penny-white {
              box-shadow: 0 5px 5px -3px transparentize($theme-value, 0.7),
              0 2px 1px -1px transparentize($theme-value, 0.76), 0 1px 1px 0 transparentize($theme-value, 0.7);
            } @else {
              box-shadow: 0 1px 1px 0 transparentize($penny-red, 0.7), 0 2px 1px 0 transparentize($penny-red, 0.80);
            }
          }

          &:focus,
          &.focused {
            box-shadow: inset 0 0 0 rem-calc(2) $theme-value, inset 0 0 0 rem-calc(4) hsl(var(--white));

            @include button-focus-blue-outline();
          }
        }
      }
    }
  }

  &--elevated {
    box-shadow: map_get($elevations, '08_dp_b_4');
  }

  &--tight {
    padding-left: 0;
    padding-right: 0;
  }

  &--condensed {
    min-width: auto;
    padding: 0 rem-calc(8);

    @include mq('lg') {
      padding: rem-calc(4) rem-calc(16);
    }
  }

  &-message {
    display: inline-flex;
    flex-direction: column;
    row-gap: rem-calc(16);
    max-width: rem-calc(288);
  }

  &-spinner {
    position: relative;

    &__spinner {
      height: 100%;
      opacity: 0;
      padding: 0.25rem;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(-500deg) scale(0.4);
      transition: 350ms;
      width: 100%;
    }

    &__icon {
      transition: 250ms;
    }

    .spinner-active & {
      &__spinner {
        opacity: 1;
        transform: none;
      }

      &__icon {
        opacity: 0;
        transform: scale(0.5);
      }
    }
  }

  &__icon {
    max-height: 100%;
    width: auto;
  }
}
