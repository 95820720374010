@import '../../subatoms/color/color_variables';

.editor-message {
  text-align: center;

  .message,
  &--warning {
    background: hsl(var(--yellow));
    border: 4px solid hsl(var(--red));
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    text-align: center;
  }

  .message {
    max-width: 100%;
    width: 1000px;
  }
}
