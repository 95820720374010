@import 'scss-mixins-functions-variables/scss/reset/list/reset-list-mixins';
@import 'scss-mixins-functions-variables/scss/layout/media-query-mixins';
@import 'scss-mixins-functions-variables/scss/rem-calc/rem-calc-functions';

.pill-list {
  $list-spacing-vertical: rem-calc(8);
  $list-spacing-horizontal: $list-spacing-vertical;

  @include reset-list();

  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: (-$list-spacing-vertical) (-$list-spacing-horizontal);

  &__item {
    display: inline-flex;
    margin: 0;
    padding: $list-spacing-vertical $list-spacing-horizontal;
  }
}
